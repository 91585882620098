import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { getImageFromCode } from '../../helpers/ImageSelector';
import { graphql } from 'babel-plugin-relay/macro';
import { useMutation } from 'react-relay';
import { PayloadError } from 'relay-runtime';
import { useMutationResponseHandler } from '../../services/app-insights/useMutationResponseHandler';
import { EnquireRegisterInstrumentMutation, EnquireRegisterInstrumentMutation$data } from './__generated__/EnquireRegisterInstrumentMutation.graphql';
import { SearchTextField } from 'itis-helix-react-core';

const enquireMutation = graphql`
  mutation EnquireRegisterInstrumentMutation ($referenceId: String!) {
    manufacturedInstrumentByReference(input: { referenceId: $referenceId }) {
      manufacturedInstrument {
        instrumentId
        serial
        modelName
        uniqueCode
      }
      errors {
        __typename
        ... on Error {
          message
        }
      }
    }
  }
`;

interface EnquireRegisterInstrumentProps {
  /*
  An instrument has already been entered, e.g. via QR code url link.
  */
  preselectedLookup?: string;

  /*
  A valid instrument was found.
  */
  onFound: (instrumentNumber: string) => void;

  registering?: boolean;
}

export type ManufacturedInstrument = {
  instrumentId: string;
  serial: string;
  modelName: string;
  uniqueCode: string;
}

export const EnquireRegisterInstrument: React.FC<EnquireRegisterInstrumentProps> = ({ onFound, preselectedLookup, registering }) => {
  const auth = useAuth();
  const [lookup, setLookup] = React.useState<string>(preselectedLookup ?? '');
  const { responseHandler } = useMutationResponseHandler();

  const [commitMutation, isInFlight] = useMutation<EnquireRegisterInstrumentMutation>(enquireMutation);

  const [manufactured, setManufactured] = useState<ManufacturedInstrument | undefined>(undefined);

  const update = (value: string) => {
    setLookup(value.toUpperCase());
  };

  const lookupError = !manufactured && lookup !== '';

  React.useEffect(() => {
    if (lookup.length > 0)
      (async () => {
        commitMutation({
          variables: { referenceId: lookup },
          onCompleted: handleComplete,
          onError: handleError
        });
      })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lookup]);

  const handleComplete = (response: EnquireRegisterInstrumentMutation$data, errors: PayloadError[] | null) => {
    responseHandler.handleSuccess(
      errors,
      response.manufacturedInstrumentByReference.errors,
      [() => setManufactured(response.manufacturedInstrumentByReference.manufacturedInstrument as ManufacturedInstrument)],
      [() => setManufactured(undefined)]
    );
  };

  const handleError = (error: Error) => {
    responseHandler.handleError(error, `An error occured: ${error.message}`);
  };

  return (
    <>
      <Typography variant="body1">Please provide the Instrument ID or Serial Number for your instrument.</Typography>

      <Grid container spacing={2} mt={'1rem'}>
        <Grid item xs={12} alignItems="center" sx={{ display: 'flex' }}>
          <SearchTextField
            term={lookup}
            onTermChange={update}
            delayMs={600}
            title='Serial Number / Instrument ID'
            displayError={lookupError}
            displayColor={manufactured ? 'success' : undefined}
            size='medium'
            fullWidth={true}
            searchIcon={false}
            width='15rem'
            disabled={registering}
          />
          <Grid container alignItems={'center'}>
            {isInFlight && (
              <>
                <CircularProgress />
                <Typography pl="1rem">Checking...</Typography>
              </>
            )}
          </Grid>
        </Grid>

        {lookupError && (
          <Grid item>
            <Typography>We couldn&apos;t find this instrument. Please check the code and try again.</Typography>
          </Grid>
        )}

        {manufactured?.uniqueCode && (
          <Grid item xs={12}>
            <Typography variant="h2" pt={'1rem'}>
              {manufactured.modelName}
            </Typography>
            <Box component="img" sx={{ width: '20rem' }} alt="Instrument" src={getImageFromCode(manufactured.uniqueCode)} />
          </Grid>
        )}

        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            disabled={!manufactured || registering || isInFlight}
            onClick={() => onFound(manufactured?.instrumentId ?? '')}
          >
            {auth.isAuthenticated ? 'Proceed' : 'Log in and proceed'}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
