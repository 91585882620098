/**
 * @generated SignedSource<<df7894870b718aed263f7b569bec204e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type InstrumentToRegisterInput = {
  companyName: string;
  countryCodeAlpha2: string;
  industrySectorName: string;
  installationDate: any;
  instrumentId: string;
};
export type RegisterInstrumentMutation$variables = {
  instrumentToRegister: InstrumentToRegisterInput;
};
export type RegisterInstrumentMutation$data = {
  readonly addInstrument: {
    readonly errors: ReadonlyArray<{
      readonly __typename: string;
      readonly message?: string;
    }> | null | undefined;
    readonly instrument: {
      readonly companyName: string;
      readonly countryCodeAlpha2: string;
      readonly customerId: string;
      readonly id: string;
      readonly industrySectorName: string;
      readonly installationDate: any;
      readonly instrumentId: string;
      readonly modelName: string;
      readonly serial: string | null | undefined;
      readonly uniqueCode: string;
    } | null | undefined;
  };
};
export type RegisterInstrumentMutation = {
  response: RegisterInstrumentMutation$data;
  variables: RegisterInstrumentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "instrumentToRegister"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "instrumentToRegister",
            "variableName": "instrumentToRegister"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "AddInstrumentPayload",
    "kind": "LinkedField",
    "name": "addInstrument",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Instrument",
        "kind": "LinkedField",
        "name": "instrument",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "customerId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "instrumentId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "serial",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "modelName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "uniqueCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "companyName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "countryCodeAlpha2",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "industrySectorName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "installationDate",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "type": "Error",
            "abstractKey": "__isError"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RegisterInstrumentMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RegisterInstrumentMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7198f074a6c39600be1b251912e07462",
    "id": null,
    "metadata": {},
    "name": "RegisterInstrumentMutation",
    "operationKind": "mutation",
    "text": "mutation RegisterInstrumentMutation(\n  $instrumentToRegister: InstrumentToRegisterInput!\n) {\n  addInstrument(input: {instrumentToRegister: $instrumentToRegister}) {\n    instrument {\n      id\n      customerId\n      instrumentId\n      serial\n      modelName\n      uniqueCode\n      companyName\n      countryCodeAlpha2\n      industrySectorName\n      installationDate\n    }\n    errors {\n      __typename\n      ... on Error {\n        __isError: __typename\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "67dc497b37d7e5591fa609bfaf2f7e78";

export default node;
