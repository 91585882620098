import UploadIcon from '@mui/icons-material/Upload';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Asset } from '../../dtos/Software';
import { toDateFormat } from '../../helpers/DateHelper';
import { graphql } from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay';
import { SoftwareAssetsGetSoftwareQuery } from './__generated__/SoftwareAssetsGetSoftwareQuery.graphql';
import DeleteAsset from './DeleteAsset';
import RollbackRelease from './RollbackRelease';
import UploadAsset from './UploadAsset';

const softwareQuery = graphql`
  query SoftwareAssetsGetSoftwareQuery($id: ID!) {
    software (productCode: $id) {
      id
      etag
      name
      currentVersion {
        id
        softwareId
        releaseNumber
        publishedDate
        lastUpdatedDate
        assets {
          id
          name
          description
          type
          size
          location
          softwareVersionId
        }
      }
      versions {
        softwareId
        releaseNumber
        publishedDate
        id
        lastUpdatedDate
      }
    }
  }
`;

interface SoftwareAssetsProps {
  softwareId: string;
}

export const SoftwareAssets: React.FC<SoftwareAssetsProps> = ({ softwareId }) => {
  const navigate = useNavigate();

  const software = useLazyLoadQuery<SoftwareAssetsGetSoftwareQuery>(softwareQuery, { id: softwareId }, { fetchPolicy: 'network-only' });

  const [uploadButtonClicked, setUploadButtonClicked] = useState<boolean>(false);

  const assets = software.software?.currentVersion?.assets.map(i => {
    return {
      id: i.id,
      description: i.description,
      location: i.location,
      name: i.name,
      size: i.size,
      type: i.type
    } as Asset;
  }) ?? [];

  const handleUploadButtonClosed = () => {
    setUploadButtonClicked(false);
  };

  if (!software.software) return <Typography variant="h1">Software could not be found</Typography>;

  return (
    <Container maxWidth={'md'}>
      {!software.software && <CircularProgress />}
      <Grid container spacing={4} direction="column">
        {software && (
          <Grid item>
            <Typography variant="h2" p="0">
              {software.software.name} - Edit Release {software.software.currentVersion?.releaseNumber}
            </Typography>
            <Typography variant="body1" pt="1rem">
              Last Updated: {toDateFormat(software.software?.currentVersion?.lastUpdatedDate, true)}
            </Typography>
          </Grid>
        )}
        <Grid item>
          {software.software.currentVersion &&
            <TableContainer component={Paper} sx={{ p: 0, backgroundColor: 'inherit' }}>
              <Table
                sx={{
                  [`& .${tableCellClasses.root}`]: {
                    borderBottom: 'none',
                    pl: 0
                  }
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell width="33%">Release Version</TableCell>
                    <TableCell>{software.software.currentVersion.releaseNumber}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                {assets.length > 0 && (
                  <TableBody>
                    {assets.map((asset: Asset) => (
                      <TableRow key={asset.id}>
                        <TableCell>{asset.description}</TableCell>
                        <TableCell>
                          {asset.name} ({asset.size})
                        </TableCell>
                        <TableCell>
                          <DeleteAsset softwareId={softwareId} versionId={software.software?.currentVersion?.id ?? ''} assetId={asset.id} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          }
        </Grid>
        <Grid item>
          <LoadingButton
            color="primary"
            variant="outlined"
            startIcon={<UploadIcon />}
            onClick={() => setUploadButtonClicked(true)}
          >
            Upload new asset
          </LoadingButton>
          {software.software.currentVersion?.id &&
            <UploadAsset
              softwareId={softwareId}
              versionId={software.software.currentVersion.id}
              handleUploadButtonClosed={handleUploadButtonClosed}
              uploadButtonClicked={uploadButtonClicked}
            />
          }
        </Grid>
        <Grid item>
          <RollbackRelease productCode={softwareId} versionId={software.software.currentVersion?.id} />
        </Grid>
        <Grid item>
          <Button onClick={() => navigate(`/software/${softwareId}`)}>Back</Button>
        </Grid>
      </Grid>
    </Container>
  );
};
