import PublishIcon from '@mui/icons-material/Publish';
import { LoadingButton } from '@mui/lab';
import { graphql } from 'babel-plugin-relay/macro';
import React from 'react';
import { useMutation } from 'react-relay';
import { PublishReleaseMutation, PublishReleaseMutation$data } from './__generated__/PublishReleaseMutation.graphql';
import { PayloadError, RecordSourceSelectorProxy } from 'relay-runtime';
import { useSnackbar } from 'notistack';
import { useMutationResponseHandler } from '../../services/app-insights/useMutationResponseHandler';

const publishMutation = graphql`
  mutation PublishReleaseMutation ($productCode: ID!) {
    publishNewRelease(input: { productCode: $productCode }) {
      softwareVersion {
        softwareId
        releaseNumber
        publishedDate
        id
        lastUpdatedDate
        assets {
          id
          name
          description
          type
          size
          location
          softwareVersionId
        }
      }
      errors {
        __typename
        ... on Error {
          message
        }
      }
    }
  }
`;

interface PublishReleaseProps {
  disabled: boolean;
  softwareId: string;
}

const PublishRelease: React.FC<PublishReleaseProps> = ({ disabled, softwareId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { responseHandler } = useMutationResponseHandler();

  const [commitMutation, isInFlight] = useMutation<PublishReleaseMutation>(publishMutation);

  const handlePublish = () => {
    commitMutation({
      variables: { productCode: softwareId },
      onCompleted: handleCompleted,
      onError: handleError,
      updater: handleVersionUpdate
    });
  };

  const handleCompleted = (response: PublishReleaseMutation$data, errors: PayloadError[] | null) => {
    responseHandler.handleSuccess(
      errors,
      response.publishNewRelease.errors,
      [() => enqueueSnackbar('Publishing latest software successful', { variant: 'success' })],
      [() => enqueueSnackbar('An error occured trying to publish the latest release', { variant: 'error' })]
    );
  };

  const handleError = (error: Error) => {
    responseHandler.handleError(error, 'An error occured trying to publish the latest release');
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleVersionUpdate = (store: RecordSourceSelectorProxy<any>, data: any) => {
    try {
      if (data.publishNewRelease) {
        const software = store.get(softwareId);

        if (!software) throw new Error('Unable to get record to link to from relay store');

        const version = store.getRootField('publishNewRelease').getLinkedRecord('softwareVersion');

        software.setLinkedRecord(version, 'currentVersion');
        software.setValue(version.getDataID(), 'currentVersionId');

        const versions = software.getLinkedRecords('versions');

        if (!versions) throw new Error('Unable to get versions from software record');

        versions?.push(version);
        software.setLinkedRecords(versions, 'versions');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <LoadingButton
        variant="contained"
        color="primary"
        loading={isInFlight}
        startIcon={<PublishIcon />}
        disabled={disabled || isInFlight}
        onClick={() => handlePublish()}
      >
        Publish New Release
      </LoadingButton>
    </>
  );
};

export default PublishRelease;