import React from 'react';
import { SoftwareReleaseCard } from './SoftwareReleaseCard';
import { CircularProgress } from '@mui/material';
import { SoftwareReleaseContent } from './SoftwareReleaseContent';
import { graphql } from 'babel-plugin-relay/macro';
import { LatestReleaseFragment$key } from './__generated__/LatestReleaseFragment.graphql';
import { useFragment } from 'react-relay';
import PublishRelease from './PublishRelease';

interface LatestReleaseProps {
  currentReleaseNumber: string | undefined | null;
  queryRef: LatestReleaseFragment$key | undefined | null;
}

const fragment = graphql`
  fragment LatestReleaseFragment on Software {
    latestRelease {
      id
      softwareId
      releaseNumber
      publishedDate
      lastUpdatedDate
      assets {
        id
        name
        description
        type
        size
        location
        softwareVersionId
      }
    }
  }
`;

const LatestRelease: React.FC<LatestReleaseProps> = ({ currentReleaseNumber: currentRelease, queryRef }) => {
  const data = useFragment(fragment, queryRef);

  return (
    <>
      <SoftwareReleaseCard
        title="Latest Release from Software Team"
        actions={data?.latestRelease && (<PublishRelease disabled={data.latestRelease.releaseNumber === currentRelease} softwareId={data.latestRelease.softwareId} />)}
      >
        {!data?.latestRelease && <CircularProgress />}
        {data?.latestRelease && <SoftwareReleaseContent version={data.latestRelease} />}
      </SoftwareReleaseCard>
    </>
  );
};

export default LatestRelease;