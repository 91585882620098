import { Button, Grid, Paper, TextField, Typography, lighten, useTheme } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { graphql } from 'babel-plugin-relay/macro';
import { isDate } from 'date-fns';
import React, { useState } from 'react';
import { useMutation } from 'react-relay';
import { ApproveWarrantyMutation, ApproveWarrantyMutation$data } from './__generated__/ApproveWarrantyMutation.graphql';
import { PayloadError } from 'relay-runtime';
import { useMutationResponseHandler } from '../../services/app-insights/useMutationResponseHandler';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

const mutation = graphql`
  mutation ApproveWarrantyMutation($warrantyId: ID!, $commencementDate: DateTime!) {
    approveWarranty(input: { warrantyId: $warrantyId, commencementDate: $commencementDate}) {
      warranty {
        id
        purchaseDate
        proofOfPurchaseFileName
        status
        instrumentId
        email
        commencementDate
        interactionDate
      }
      errors {
        __typename
        ... on Error {
          message
        }
      }
    }
  }
`;

export interface ApproveWarrantyProps {
  warrantyId: string;
}

const ApproveWarranty: React.FC<ApproveWarrantyProps> = ({ warrantyId }) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [commitMutation, IsInFlight] = useMutation<ApproveWarrantyMutation>(mutation);
  const { responseHandler } = useMutationResponseHandler();
  const [approved, setApproved] = useState(false);
  const navigate = useNavigate();
  const [commence, setCommence] = useState<Date>(new Date);

  const handleDateChange = (date: Date | null) => {
    date && setCommence(date);
  };

  const dateError = (): string | null => {
    if (!isDate(commence)) return 'Invalid date';
    return null;
  };

  const handleCompleted = (response: ApproveWarrantyMutation$data, errors: PayloadError[] | null) => {
    responseHandler.handleSuccess(
      errors,
      response.approveWarranty.errors,
      [() => {
        setApproved(response.approveWarranty.warranty?.status === 'ACTIVE');
        enqueueSnackbar('Warranty approved successfully', { variant: 'success' });
        navigate('/');
      }],
      [() => enqueueSnackbar('An error occured attempting to approve this warranty', { variant: 'error' })]
    );
  };

  const handleError = (error: Error) => {
    responseHandler.handleError(error, 'An error occured attempting to approve this warranty');
  };

  const handleApproval = () => {
    commitMutation({
      variables: { warrantyId, commencementDate: commence },
      onCompleted: handleCompleted,
      onError: handleError
    });
  };

  return (
    <>
      <Paper sx={{ mt: '1rem', backgroundColor: lighten(theme.palette.primary.main, 0.7) }}>
        <Grid item>
          <Typography variant="h1">Approve Warranty</Typography>
          <Typography variant="h2">Enter commencement date and approve the warranty.</Typography>
        </Grid>
        <Grid item>
          <DatePicker
            label="Warranty Commencement"
            inputFormat="dd/MM/yyyy"
            value={commence}
            onChange={(e) => handleDateChange(e)}
            renderInput={(params) => <TextField {...params} error={dateError() !== null} helperText={dateError()} />}
          />
        </Grid>
        <Grid item>
          <Button
            onClick={handleApproval}
            sx={{ marginTop: '2rem' }}
            variant="contained"
            disabled={approved || IsInFlight}
            color="primary">
            Approve
          </Button>
        </Grid>
      </Paper>
    </>
  );
};

export default ApproveWarranty;