import { Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppInsightsPageview } from '../../services/app-insights/useAppInsightsPageview';
import { EnquireRegisterInstrument } from '../instrument-registration/EnquireRegisterInstrument';
import { RegisterInstrument } from '../instrument-registration/RegisterInstrument';
import StandardContainerLayout from '../../layouts/StandardContainerLayout';
import { SecurePage } from './SecurePage';

export const RegisterInstrumentPage: React.FC = () => {
  useAppInsightsPageview('Register instrument');

  const { lookup } = useParams();
  const auth = useAuth();
  const [instrumentNo, setInstrumentNo] = React.useState(lookup ?? '');
  const [registering, setRegistering] = React.useState<boolean>(Boolean(lookup) && auth.isAuthenticated);
  const navigate = useNavigate();

  const registerInstrument = (instrumentId: string) => {
    navigate(`/register-instrument/${instrumentId}`);
    setInstrumentNo(instrumentId);
    setRegistering(true);
  };

  const cancelRegistration = () => {
    setRegistering(false);
  };

  useEffect(() => {
    setRegistering(Boolean(lookup) && auth.isAuthenticated);
  }, [lookup, auth.isAuthenticated]);

  return (
    <StandardContainerLayout>
      <Typography variant="h1">Register New Instrument</Typography>
      <Grid container spacing={6}>
        <Grid item md={6}>
          <EnquireRegisterInstrument onFound={registerInstrument} preselectedLookup={lookup} registering={registering} />
        </Grid>
        <Grid item md={6}>
          {registering && (
            <SecurePage>
              <RegisterInstrument
                instrumentNumberOrSerialNumber={instrumentNo}
                onSuccess={() => {
                  localStorage.removeItem('registering');
                  navigate('/dashboard');
                }}
                onCancel={cancelRegistration}
              />
            </SecurePage>
          )}
        </Grid>
      </Grid>
    </StandardContainerLayout>
  );
};
