
import React from 'react';
import DownloadAsset from './DownloadAsset';
import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { Asset } from '../../dtos/Software';

interface SoftwareDownloadDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  assets: Asset[];
  releaseVersion: string;
}

export const SoftwareDownloadDialog: React.FC<SoftwareDownloadDialogProps> = ({ open, onClose, title, assets, releaseVersion }) => {

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth="sm">
      <DialogTitle>
        {title} {releaseVersion}
      </DialogTitle>
      <DialogContent>
        <Typography>There are {assets.length} downloads available.</Typography>
        {assets.map((asset) => (
          <DownloadAsset key={asset.id} asset={asset} />
        ))}
      </DialogContent>
    </Dialog>
  );
};
