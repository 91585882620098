import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import * as BlobStorageService from '../../services/BlobStorageService';
import { graphql } from 'babel-plugin-relay/macro';
import { useMutation } from 'react-relay';
import { PayloadError, RecordSourceSelectorProxy } from 'relay-runtime';
import { UploadAssetGetSasUrlMutation, UploadAssetGetSasUrlMutation$data } from './__generated__/UploadAssetGetSasUrlMutation.graphql';
import { UploadAssetMutation, UploadAssetMutation$data } from './__generated__/UploadAssetMutation.graphql';
import { useMutationResponseHandler } from '../../services/app-insights/useMutationResponseHandler';

const blobSasUrlMutation = graphql`
  mutation UploadAssetGetSasUrlMutation($productCode: ID!, $versionId: ID!, $assetName: String!) {
    assetBlobUrlForUpload(input: { productCode: $productCode, versionId: $versionId, assetName: $assetName }) {
      string
      errors {
        __typename
        ... on Error {
          message
        }
      }
    }
  }
`;

const assetMutation = graphql`
  mutation UploadAssetMutation($productCode: ID!, $versionId: ID!, $asset: AssetInput!) {
    uploadSoftwareAsset(input: {productCode: $productCode, versionId: $versionId, asset: $asset}) {
      asset {
        softwareVersionId
        name
        description
        type
        size
        id
        location
      }
      errors {
        __typename
        ... on Error {
          message
        }
      }
    }
  }
`;

export interface UploadAssetProps {
  softwareId: string;
  versionId: string;
  handleUploadButtonClosed: () => void;
  uploadButtonClicked: boolean;
}

const UploadAsset: React.FC<UploadAssetProps> = ({ softwareId, versionId, handleUploadButtonClosed, uploadButtonClicked }) => {
  const [description, setDescription] = useState<string>('');
  const { enqueueSnackbar } = useSnackbar();
  const [fileToUpload, setFileToUpload] = useState<File | undefined>();
  const [blobUrl, setBlobUrl] = useState<string>('');
  const { responseHandler } = useMutationResponseHandler();

  const [getBlobSasUploadUrl] = useMutation<UploadAssetGetSasUrlMutation>(blobSasUrlMutation);
  const [addAsset] = useMutation<UploadAssetMutation>(assetMutation);

  const handleCompletedSasUrl = (response: UploadAssetGetSasUrlMutation$data, errors: PayloadError[] | null) => {
    responseHandler.handleSuccess(
      errors,
      response.assetBlobUrlForUpload.errors,
      [() => { response.assetBlobUrlForUpload.string && setBlobUrl(response.assetBlobUrlForUpload.string); }],
      [() => enqueueSnackbar('An error acquiring an upload url', { variant: 'error' })]
    );
  };

  const handleAddAssetComplete = (response: UploadAssetMutation$data, errors: PayloadError[] | null) => {
    responseHandler.handleSuccess(
      errors,
      response.uploadSoftwareAsset.errors,
      [() => enqueueSnackbar('Uploaded asset successfully', { variant: 'success' })],
      [() => enqueueSnackbar('An error occured uploading the asset', { variant: 'error' })]
    );
  };

  const handleError = (error: Error) => {
    responseHandler.handleError(error, 'An error occured uploading the asset');
  };

  const handleUploadNewAssetAsync = async (event: React.ChangeEvent<HTMLInputElement>) => {
    handleUploadButtonClosed();

    if (event.target.files === null || description.trim().length === 0) {
      return;
    }

    const file = event.target.files[0];

    setFileToUpload(file);

    getBlobSasUploadUrl({
      variables: { productCode: softwareId, versionId, assetName: file.name },
      onCompleted: handleCompletedSasUrl,
      onError: handleError
    });
  };

  const uploadBlobAsync = async () => {
    if (blobUrl && fileToUpload) {
      await BlobStorageService.UploadFileToBlobStorageAsync(
        blobUrl,
        fileToUpload,
        () => updateDatabase(),
        (message) => enqueueSnackbar(message, { variant: 'error' })
      );
    }
  };

  const updateDatabase = () => {
    if (fileToUpload) {
      addAsset({
        variables: {
          productCode: softwareId, versionId, asset: {
            name: fileToUpload.name,
            description: description,
            type: fileToUpload.type.split('/')[1] ?? fileToUpload.name.split('.').pop(),
            size: fileToUpload.size.toString()
          }
        },
        onCompleted: handleAddAssetComplete,
        onError: handleError,
        updater: handleAssetUpdate
      });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleAssetUpdate = (store: RecordSourceSelectorProxy<any>, data: any) => {
    try {
      if (data.uploadSoftwareAsset) {
        const asset = store.getRootField('uploadSoftwareAsset').getLinkedRecord('asset');

        const version = store.get(versionId);
        if (!version) throw new Error('Unable to get record to link to from relay store');

        const assets = version.getLinkedRecords('assets');
        if (!assets) throw new Error('Unable to get assets from software version');

        assets?.push(asset);
        version.setLinkedRecords(assets, 'assets');
      } else {
        throw new Error('New record is null');
      }
    } catch (error) {
      enqueueSnackbar(
        'An error occured attempting to link data in the GraphQL Relay Store, you may need to refresh the page for a accurate.',
        { variant: 'error' }
      );
      console.log(error);
    }
  };

  useEffect(() => {
    uploadBlobAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blobUrl, fileToUpload]);

  return (
    <>
      <Dialog open={uploadButtonClicked} onClose={handleUploadButtonClosed} maxWidth="md">
        <DialogTitle>Upload a new asset</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Uploading an asset that has the same file name as an existing one will overwrite the content of the existing asset.
          </DialogContentText>
          <TextField
            label="Description"
            type="text"
            autoFocus
            margin="dense"
            required
            fullWidth
            variant="standard"
            onChange={(e) => setDescription(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUploadButtonClosed} sx={{ mr: '1rem' }}>
            Cancel
          </Button>
          <input
            id="upload-asset"
            type="file"
            onChange={(event) => handleUploadNewAssetAsync(event)}
            onClick={(event) => {
              (event.target as HTMLInputElement).value = '';
            }}
            hidden
            disabled={description.trim().length === 0}
          />
          <label htmlFor="upload-asset">
            <Button color="primary" component="span" startIcon={<AddIcon />} sx={{ mr: '1rem' }} disabled={description.trim().length === 0}>
              Select a file
            </Button>
          </label>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UploadAsset;
