import { useParams } from 'react-router-dom';
import { useAppInsightsPageview } from '../../services/app-insights/useAppInsightsPageview';
import { ManageSoftware } from '../admin/ManageSoftware';
import { NotFoundPage } from './NotFoundPage';
import StandardContainerLayout from '../../layouts/StandardContainerLayout';

export const ManageSoftwarePage: React.FC = () => {
  useAppInsightsPageview('Manage software');

  const { id } = useParams();

  if (!id) return <NotFoundPage />;

  return (
    <StandardContainerLayout>
      <ManageSoftware softwareId={id} />
    </StandardContainerLayout>
  );
};
