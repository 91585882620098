export const isInWarranty = (commencementDate: Date) => {
  const currentDate = new Date();
  const warrantyDays = 730;

  if (!commencementDate) return false;

  commencementDate = new Date(commencementDate);
  const daysDifference = (currentDate.getTime() - commencementDate.getTime()) / (1000 * 60 * 60 * 24);

  return daysDifference < warrantyDays;
};