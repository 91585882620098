import { BlockBlobClient, AnonymousCredential } from '@azure/storage-blob';


export const UploadFileToBlobStorageAsync = async (
  blockBlobURL: string | undefined,
  file: File,
  success: (message: string) => void,
  error: (message: string) => void
  // eslint-disable-next-line max-params
): Promise<boolean> => {
  if (!blockBlobURL) {
    error(`Failed to upload file ${file.name} due to invalid Blob SAS URL.`);
    return false;
  }

  const blobClient = new BlockBlobClient(blockBlobURL, new AnonymousCredential());

  return await blobClient
    .uploadData(file)
    .then(() => {
      success(`Uploaded file ${file.name}`);
      return true;
    })
    .catch((reason: string) => {
      error(`Failed to upload file ${file.name} due to ${reason}`);
      return false;
    });
};
