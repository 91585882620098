import { Card, CardActionArea, CardContent, CardMedia, CircularProgress, Grid, Typography } from '@mui/material';
import React, {  } from 'react';
import { useNavigate } from 'react-router-dom';
import { Software } from '../../dtos/Software';
import { toDateFormat } from '../../helpers/DateHelper';
import { getImageFromArray } from '../../helpers/ImageSelector';
import { graphql } from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay';
import { SoftwareProductsManageAllSoftwareQuery } from './__generated__/SoftwareProductsManageAllSoftwareQuery.graphql';

const manageAllSoftwareQuery = graphql`
  query SoftwareProductsManageAllSoftwareQuery {
    manageAllSoftware {
      edges @required (action: THROW) {
        node {
          id
          etag
          name
          currentVersion {
            id
            softwareId
            releaseNumber
            publishedDate
            lastUpdatedDate
            assets {
              id
              softwareVersionId
              name
              description
              type
              size
              location
            }
          }
          supportedInstruments
          versions {
            id
            softwareId
            releaseNumber
            publishedDate
            lastUpdatedDate
          }
        }
      }
    }
  }
`;

export const SoftwareProducts: React.FC = () => {
  const navigate = useNavigate();

  const allSoftware = useLazyLoadQuery<SoftwareProductsManageAllSoftwareQuery>(manageAllSoftwareQuery, {});

  const getCurrentRelease = (software: Software) => {
    return software?.currentVersion;
  };

  return (
    <Grid container spacing={4} justifyContent={'center'}>
      <Grid item xs={12}>
        <Typography variant="h1">Current Software Products</Typography>

        {!allSoftware.manageAllSoftware && <CircularProgress />}
        {allSoftware.manageAllSoftware && allSoftware.manageAllSoftware.edges.length === 0 && (
          <>
            <Typography>There are no software products in the Customer Portal yet.</Typography>
            <Typography variant="caption">
              This usually only happens if you have deployed the Customer Portal for the first time. You should restore the initial software products
              using the "Adding New Software Platforms" feature on this page.
            </Typography>
          </>
        )}

        {allSoftware.manageAllSoftware && allSoftware.manageAllSoftware?.edges.length > 0 && (
          <Grid container spacing={2}>
            {allSoftware.manageAllSoftware.edges.map(edge => {
              return {
                id: edge.node.id,
                name: edge.node.name,
                currentVersion: edge.node.currentVersion,
                supportedInstruments: [...edge.node.supportedInstruments],
                versions: []
              } as Software;
            }).map((software, i) => (
              <Grid item xs={12} md={6} key={i}>
                <Card sx={{ p: 0 }}>
                  <CardActionArea onClick={() => navigate(`/software/${software.id}`)}>
                    <Grid container>
                      <Grid item>
                        <CardMedia component="img" sx={{ height: '10rem' }} image={getImageFromArray([...software.supportedInstruments])} />
                      </Grid>
                      <Grid item>
                        <CardContent>
                          <Typography variant="h2">{software.name}</Typography>
                          <Typography variant="body1">Latest Release: {getCurrentRelease(software)?.releaseNumber ?? 'N/A'}</Typography>
                          <Typography variant="body1">Published: {toDateFormat(getCurrentRelease(software)?.publishedDate)}</Typography>
                        </CardContent>
                      </Grid>
                    </Grid>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
