import Grid, { GridProps } from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import WaveImage from '../assets/wave-no-edges-white-translucent.png';

/**
 * A MUI `Grid` component with a "wave" background in
 * white on grey.
 */
const WaveGrid = styled(Grid)<GridProps>(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  backgroundImage: `url(${WaveImage})`,
  backgroundSize: '100%',
  backgroundRepeat: 'no-repeat'
}));

export default WaveGrid;
