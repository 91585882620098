import { Button, Grid, lighten, Paper, Typography, useTheme } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useAppInsightsPageview } from '../../services/app-insights/useAppInsightsPageview';
import { Role } from '../../services/authentication/Roles';
import { EnquireRegisterInstrument } from '../instrument-registration/EnquireRegisterInstrument';
import { SecureComponent } from './SecureComponent';
import StandardContainerLayout from '../../layouts/StandardContainerLayout';

export const Homepage: React.FC = () => {
  useAppInsightsPageview('Homepage');

  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <StandardContainerLayout waveBackground>
      <Grid container spacing={3} flexGrow={1} justifyContent={'center'}>
        <Grid item xs={12} sm={12}>
          <Typography variant="h1">Welcome to the Novacyt Customer Portal</Typography>
          <Typography variant="h2">How can we help you today?</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ minHeight: '22rem', p: '2rem', backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText }}>
            <Typography variant="h2">Register a new instrument.</Typography>
            <Typography>This will help you get access to the latest software for making the most of your instrument.</Typography>
            <Paper sx={{ mt: '1rem' }}>
              <EnquireRegisterInstrument onFound={(instrumentId) => navigate(`/register-instrument/${instrumentId}`)} />
            </Paper>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ minHeight: '22rem', p: '2rem', backgroundColor: theme.palette.secondary.light, color: theme.palette.secondary.contrastText }}>
            <Typography variant="h2">See your personalised dashboard.</Typography>
            <Typography>See information about all the instruments you&apos;ve registered.</Typography>
            <Button component={Link} to="/dashboard" sx={{ marginTop: '2rem' }} variant="contained" color="primary">
              Go to your dashboard
            </Button>
          </Paper>
        </Grid>
        <SecureComponent permittedRoles={[Role.Admin, Role.GenesigReleaseManager]}>
          <Grid item xs={12} md={6}>
            <Paper sx={{ minHeight: '22rem', p: '2rem', backgroundColor: lighten(theme.palette.primary.main, 0.7) }}>
              <Typography variant="h2">Maintain the software releases.</Typography>
              <Typography>
                You&apos;re authorised to maintain the Novacyt instrument software. You can approve the latest releases for making them available to
                customers.
              </Typography>
              <Button component={Link} to="/software-products" sx={{ marginTop: '2rem' }} variant="contained" color="primary">
                Go to software maintenance
              </Button>
            </Paper>
          </Grid>
        </SecureComponent>
      </Grid>
    </StandardContainerLayout>
  );
};
