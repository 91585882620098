import StandardContainerLayout from '../../layouts/StandardContainerLayout';
import { useAppInsightsPageview } from '../../services/app-insights/useAppInsightsPageview';
import { AllSoftware } from '../software/AllSoftware';

export const AllSoftwarePage: React.FC = () => {
  useAppInsightsPageview('Software products');

  return (
    <StandardContainerLayout>
      <AllSoftware />
    </StandardContainerLayout>
  );
};
