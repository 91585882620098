import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { toDateFormat } from '../../helpers/DateHelper';
import { MyInstrumentsQuery$data } from './__generated__/MyInstrumentsQuery.graphql';
import { useEffect, useState } from 'react';
import WarrantyCell from './WarrantyCell';
import AllDocumentsModal from './AllDocumentsModal';
import DocumentCell from './DocumentCell';

type Instrument = MyInstrumentsQuery$data['myInstruments']['edges'][0];

interface InstrumentTableProps {
  instruments: MyInstrumentsQuery$data;
}

const compare = (a: Instrument, b: Instrument) => {
  return a.node.installationDate.localeCompare(b.node.installationDate || '');
};

export const InstrumentTable: React.FC<InstrumentTableProps> = ({ instruments }) => {
  const [showDownloadButton, setShowDownloadButton] = useState(true);
  const [showWarrantyStatus, setShowWarrantyStatus] = useState(true);
  const [showSerialNumber, setShowSerialNumber] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [selectedInstrumentId, setSelectedInstrumentId] = useState<string | null | undefined>(null);

  const handleResize = () => {
    setShowDownloadButton(window.innerWidth > 750);
    setShowWarrantyStatus(window.innerWidth > 750);
    setShowSerialNumber(window.innerWidth > 750);
  };

  const handleShowDocumentsModal = (instrumentId: string) => {
    setSelectedInstrumentId(instrumentId);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Instrument</TableCell>
              <TableCell>Instrument ID</TableCell>
              {showSerialNumber && <TableCell>Serial Number</TableCell>}
              <TableCell>Installation Date</TableCell>
              {showWarrantyStatus ? <TableCell>Warranty Status</TableCell> : <TableCell />}
              {showDownloadButton ? <TableCell></TableCell> : <TableCell />}
            </TableRow>
          </TableHead>
          <TableBody>
            {instruments?.myInstruments?.edges
              ?.toSorted((a: Instrument, b: Instrument) => compare(a, b))
              ?.map((instrument: Instrument) => (
                <TableRow key={instrument.node.instrumentId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>{instrument.node.modelName}</TableCell>
                  <TableCell>{instrument.node.instrumentId}</TableCell>
                  {showSerialNumber && <TableCell>{instrument.node.serial}</TableCell>}
                  <TableCell>{toDateFormat(instrument.node.installationDate)}</TableCell>
                  <WarrantyCell
                    commencementDate={instrument?.node?.warranties[0]?.commencementDate}
                    showIcon={showWarrantyStatus}
                    warrantyStatus={instrument?.node?.warranties[0]?.status}
                    warrantyId={instrument?.node?.warranties[0]?.id}
                    customerId={instrument?.node?.customerId}
                    instrumentId={instrument.node.instrumentId} />
                  <DocumentCell instrumentId={instrument.node.instrumentId} onOpenModal={handleShowDocumentsModal}/>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AllDocumentsModal
        open={openModal}
        onClose={handleCloseModal}
        instrumentId={selectedInstrumentId || ''}
      />
    </>
  );
};