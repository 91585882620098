import React from 'react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { ReactNode } from 'react';
// import ErrorBoundary from './ErrorBoundary';
import { ErrorBoundary, GraphqlErrorAlert } from 'itis-helix-react-core';

type GraphQLErrorBoundaryProps = {
  children?: ReactNode;
};

/**
 * A React Error Boundary to catch errors from children using GraphQL data queries.
 * On any error, displays an alert prompting user to check they are signed in and authorised.
 */
const GraphQLErrorBoundary: React.FC<GraphQLErrorBoundaryProps> = ({ children }: GraphQLErrorBoundaryProps) => {
  const ai = useAppInsightsContext();
  const appInsights = ai.getAppInsights();

  const trackError = (message: string) => {
    appInsights.trackException({
      error: new Error(message)
    });
  };

  return (
    <ErrorBoundary track={trackError} fallback={
      <GraphqlErrorAlert errorMessage='Error retrieving data - please check you are signed in and authorised to access data' />}>
      {children}
    </ErrorBoundary>
  );
};

export default GraphQLErrorBoundary;
