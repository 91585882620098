import { Button, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, {  } from 'react';
import { Link } from 'react-router-dom';
import { toDateFormat } from '../../helpers/DateHelper';
import { graphql } from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay';
import { ManageSoftwareGetSoftwareQuery } from './__generated__/ManageSoftwareGetSoftwareQuery.graphql';
import CurrentRelease from './CurrentRelease';
import LatestRelease from './LatestRelease';

const softwareQuery = graphql`
  query ManageSoftwareGetSoftwareQuery($id: ID!) {
    node (id: $id) {
      ... on Software {
        id
        etag
        name
        currentVersionId
        ...CurrentReleaseFragment
        ...LatestReleaseFragment
        versions {
          softwareId
          releaseNumber
          publishedDate
          id
          lastUpdatedDate
        }
      }
    }
  }
`;

interface ManageSoftwareProps {
  softwareId: string;
}

export const ManageSoftware: React.FC<ManageSoftwareProps> = ({ softwareId }) => {
  const data = useLazyLoadQuery<ManageSoftwareGetSoftwareQuery>(softwareQuery, { id: softwareId });

  if (!data.node) return <Typography variant="h1">Software could not be found</Typography>;
  return (
    <>
      {!data.node && <CircularProgress />}
      {data.node && <Typography variant="h1">{data.node.name}</Typography>}
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <LatestRelease currentReleaseNumber={data.node.versions?.find(i => i.id === data.node?.currentVersionId)?.releaseNumber} queryRef={data.node} />
        </Grid>
        <Grid item xs={12} md={6}>
          <CurrentRelease queryRef={data.node} />
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper} sx={{ p: '2rem' }}>
            <Typography variant="h2">Version History</Typography>
            {!data.node && <CircularProgress />}
            {data && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Version</TableCell>
                    <TableCell>Published Date</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {data.node.versions && data.node.versions
                    .filter((f) => f.id !== data.node?.currentVersionId)
                    .sort((a, b) => (a.releaseNumber < b.releaseNumber ? 1 : -1))
                    .map((version) => (
                      <TableRow key={version.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell>{version.releaseNumber}</TableCell>
                        <TableCell>{toDateFormat(version.publishedDate)}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        </Grid>

        <Grid item>
          <Button component={Link} to="/guides/software-release">
            genesig software release guide
          </Button>
        </Grid>

        <Grid item>
          <Button component={Link} to="/software-products">
            Back to all software
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
