/**
 * @generated SignedSource<<f379ac6c05553969a07cb88065578c36>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UploadAssetGetSasUrlMutation$variables = {
  assetName: string;
  productCode: string;
  versionId: string;
};
export type UploadAssetGetSasUrlMutation$data = {
  readonly assetBlobUrlForUpload: {
    readonly errors: ReadonlyArray<{
      readonly __typename: string;
      readonly message?: string;
    }> | null | undefined;
    readonly string: string | null | undefined;
  };
};
export type UploadAssetGetSasUrlMutation = {
  response: UploadAssetGetSasUrlMutation$data;
  variables: UploadAssetGetSasUrlMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "assetName"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "productCode"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "versionId"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "assetName",
            "variableName": "assetName"
          },
          {
            "kind": "Variable",
            "name": "productCode",
            "variableName": "productCode"
          },
          {
            "kind": "Variable",
            "name": "versionId",
            "variableName": "versionId"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "AssetBlobUrlForUploadPayload",
    "kind": "LinkedField",
    "name": "assetBlobUrlForUpload",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "string",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "type": "Error",
            "abstractKey": "__isError"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UploadAssetGetSasUrlMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UploadAssetGetSasUrlMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "2dbf57ecc0d8a7aa489d8cbfa04202a5",
    "id": null,
    "metadata": {},
    "name": "UploadAssetGetSasUrlMutation",
    "operationKind": "mutation",
    "text": "mutation UploadAssetGetSasUrlMutation(\n  $productCode: ID!\n  $versionId: ID!\n  $assetName: String!\n) {\n  assetBlobUrlForUpload(input: {productCode: $productCode, versionId: $versionId, assetName: $assetName}) {\n    string\n    errors {\n      __typename\n      ... on Error {\n        __isError: __typename\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "dfca98bd0275b98139921bfb0fba49fe";

export default node;
