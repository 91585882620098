import GenesigQ16 from '../assets/genesig-q16.jpg';
import GenesigQ32 from '../assets/genesig-q32.jpg';
import MyGoMini from '../assets/mygo-mini.jpg';
import MyGoPro from '../assets/mygo-pro.jpg';
import MyGoProESR from '../assets/mygo-pro-esr.jpg';
import Placeholder from '../assets/placeholder.jpg';
import Dualo32 from '../assets/dualo-32.png';
import Dualo32R2 from '../assets/dualo-32-r2.png';

export const getImageFromArray = (uniqueCodes: string[]) => {
  if (uniqueCodes.some((code) => ['Q16', 'Q16S', 'Q16D'].includes(code))) return GenesigQ16;
  if (uniqueCodes.some((code) => ['Q32', 'Q322'].includes(code))) return GenesigQ32;
  if (uniqueCodes.some((code) => ['MMS', 'MGM', 'A16', 'S16'].includes(code))) return MyGoMini;
  if (uniqueCodes.some((code) => ['A32A', 'MGP', 'R32A', 'R32S', 'A32'].includes(code))) return MyGoPro;
  if (uniqueCodes.some((code) => ['MPE'].includes(code))) return MyGoProESR;
  if (uniqueCodes.some((code) => ['D32'].includes(code))) return Dualo32;
  if (uniqueCodes.some((code) => ['DR2'].includes(code))) return Dualo32R2;
  return Placeholder;
};

export const getImageFromCode = (uniqueCode: string) => {
  return getImageFromArray([uniqueCode]);
};
