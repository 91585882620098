import { Button, TableCell } from '@mui/material';

interface DocumentCell {
  instrumentId: string;
  onOpenModal: (instrumentId: string) => void;
}

const DocumentCell: React.FC<DocumentCell> = ({ instrumentId, onOpenModal }) => {

  const handleClick = () => {
    onOpenModal(instrumentId);
  };
  return (
    <TableCell>
      <Button onClick={handleClick}>Download Documents</Button>
    </TableCell>
  );
};

export default DocumentCell;