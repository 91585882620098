/**
 * @generated SignedSource<<71b95de5dba055f8f4958cc35d8e03e3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DownloadAssetMutation$variables = {
  assetId: string;
};
export type DownloadAssetMutation$data = {
  readonly assetBlobUrlForDownload: {
    readonly errors: ReadonlyArray<{
      readonly __typename: string;
      readonly message?: string;
    }> | null | undefined;
    readonly string: string | null | undefined;
  };
};
export type DownloadAssetMutation = {
  response: DownloadAssetMutation$data;
  variables: DownloadAssetMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "assetId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "assetId",
            "variableName": "assetId"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "AssetBlobUrlForDownloadPayload",
    "kind": "LinkedField",
    "name": "assetBlobUrlForDownload",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "string",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "type": "Error",
            "abstractKey": "__isError"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DownloadAssetMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DownloadAssetMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "836b81bf5ec6c4ebce502a14347349ad",
    "id": null,
    "metadata": {},
    "name": "DownloadAssetMutation",
    "operationKind": "mutation",
    "text": "mutation DownloadAssetMutation(\n  $assetId: ID!\n) {\n  assetBlobUrlForDownload(input: {assetId: $assetId}) {\n    string\n    errors {\n      __typename\n      ... on Error {\n        __isError: __typename\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d99f2b4560aeff8eb1237d3bbbdf07c0";

export default node;
