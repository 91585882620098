/**
 * @generated SignedSource<<7df5e7c2d4745885abcca2b7e8227b20>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteAssetMutation$variables = {
  assetId: string;
  productCode: string;
  versionId: string;
};
export type DeleteAssetMutation$data = {
  readonly deleteSoftwareAsset: {
    readonly errors: ReadonlyArray<{
      readonly __typename: string;
      readonly message?: string;
    }> | null | undefined;
    readonly software: {
      readonly currentVersion: {
        readonly assets: ReadonlyArray<{
          readonly description: string;
          readonly id: string;
          readonly location: string | null | undefined;
          readonly name: string;
          readonly size: string;
          readonly softwareVersionId: string;
          readonly type: string;
        }>;
        readonly id: string;
        readonly lastUpdatedDate: any | null | undefined;
        readonly publishedDate: any;
        readonly releaseNumber: string;
        readonly softwareId: string;
      } | null | undefined;
      readonly etag: string;
      readonly id: string;
      readonly name: string;
      readonly supportedInstruments: ReadonlyArray<string>;
      readonly versions: ReadonlyArray<{
        readonly id: string;
        readonly lastUpdatedDate: any | null | undefined;
        readonly publishedDate: any;
        readonly releaseNumber: string;
        readonly softwareId: string;
      }>;
    } | null | undefined;
  };
};
export type DeleteAssetMutation = {
  response: DeleteAssetMutation$data;
  variables: DeleteAssetMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "assetId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "productCode"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "versionId"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "softwareId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "releaseNumber",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "publishedDate",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastUpdatedDate",
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "assetId",
            "variableName": "assetId"
          },
          {
            "kind": "Variable",
            "name": "productCode",
            "variableName": "productCode"
          },
          {
            "kind": "Variable",
            "name": "versionId",
            "variableName": "versionId"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "DeleteSoftwareAssetPayload",
    "kind": "LinkedField",
    "name": "deleteSoftwareAsset",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Software",
        "kind": "LinkedField",
        "name": "software",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "etag",
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "supportedInstruments",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SoftwareVersion",
            "kind": "LinkedField",
            "name": "versions",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v3/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SoftwareVersion",
            "kind": "LinkedField",
            "name": "currentVersion",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v3/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Asset",
                "kind": "LinkedField",
                "name": "assets",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "softwareVersionId",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "size",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "location",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "type": "Error",
            "abstractKey": "__isError"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteAssetMutation",
    "selections": (v9/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "DeleteAssetMutation",
    "selections": (v9/*: any*/)
  },
  "params": {
    "cacheID": "5d6f69d5613b444583a9702d4ecba391",
    "id": null,
    "metadata": {},
    "name": "DeleteAssetMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteAssetMutation(\n  $productCode: ID!\n  $versionId: ID!\n  $assetId: ID!\n) {\n  deleteSoftwareAsset(input: {productCode: $productCode, versionId: $versionId, assetId: $assetId}) {\n    software {\n      id\n      etag\n      name\n      supportedInstruments\n      versions {\n        softwareId\n        releaseNumber\n        publishedDate\n        id\n        lastUpdatedDate\n      }\n      currentVersion {\n        softwareId\n        releaseNumber\n        publishedDate\n        id\n        lastUpdatedDate\n        assets {\n          softwareVersionId\n          name\n          description\n          type\n          size\n          id\n          location\n        }\n      }\n    }\n    errors {\n      __typename\n      ... on Error {\n        __isError: __typename\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4b03dc3e40a272de9ed6d8c344ac21d0";

export default node;
