import React from 'react';

const getCookie = (name: string) => {
  const matches = document.cookie.match(new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'));
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const setCookie = (name: string, value: string, options: any = {}) => {
  options = {
    path: '/',
    samesite: 'strict',
    // add other defaults here if necessary
    ...options
  };

  let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);

  for (const optionKey in options) {
    updatedCookie += '; ' + optionKey;
    const optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += '=' + optionValue;
    }
  }

  document.cookie = updatedCookie;
};

const appInsightsCookieName = 'Novacyt.CookieConsent.FirstPartyAnalytics';

const getAppInsightsCookiePermitted = () => {
  const value = getCookie(appInsightsCookieName);
  if (!value) return undefined;
  if (value === 'Yes') return true;
  return false;
};

const setAppInsightsCookiePermitted = (isAllowed: boolean) =>
  setCookie(appInsightsCookieName, isAllowed === true ? 'Yes' : 'No', { 'max-age': 60 * 60 * 24 * 365 * 2 });

export interface AppInsightsCookie {
  isPermitted: boolean;
  isDefined: boolean;
  permit: () => void;
  forbid: () => void;
}

export const useAppInsightsCookie = (): AppInsightsCookie => {
  const [isPermitted, setIsPermitted] = React.useState<boolean | undefined>(getAppInsightsCookiePermitted());

  React.useEffect(() => {
    if (isPermitted !== undefined) {
      setAppInsightsCookiePermitted(isPermitted);
    }
  }, [isPermitted]);

  const isDefined = isPermitted !== undefined;

  const permit = () => setIsPermitted(true);
  const forbid = () => setIsPermitted(false);

  return { isPermitted: isPermitted === true, isDefined, permit, forbid };
};
