import React from 'react';
import { useParams } from 'react-router-dom';
import WarrantyProof from './WarrantyProof';
import StandardContainerLayout from '../../layouts/StandardContainerLayout';
import { Grid, Typography } from '@mui/material';

const WarrantyApprovalPage: React.FC = () => {
  const { id } = useParams();
  return (
    <>
      <StandardContainerLayout waveBackground>
        {
          id &&
          <Grid container spacing={3} justifyContent={'center'} flexGrow={1}>
            <Grid item xs={12}>
              <Typography variant="h1">Warranty Approval</Typography>
            </Grid>
            <WarrantyProof warrantyId={id} />
          </Grid>
        }
      </StandardContainerLayout>
    </>
  );
};

export default WarrantyApprovalPage;