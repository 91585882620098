/**
 * @generated SignedSource<<22e977c6c5fa791b49660b33fd33ba76>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type WarrantyStatusEnum = "ACTIVE" | "EXPIRED" | "INIT" | "PENDING" | "%future added value";
export type WarrantyProofQuery$variables = {
  id: string;
};
export type WarrantyProofQuery$data = {
  readonly warranty: {
    readonly email: string | null | undefined;
    readonly id: string;
    readonly instrument: {
      readonly companyName: string;
      readonly countryCodeAlpha2: string;
      readonly industrySectorName: string;
      readonly instrumentId: string;
      readonly modelName: string;
    } | null | undefined;
    readonly instrumentId: string;
    readonly proofOfPurchaseFileName: string | null | undefined;
    readonly purchaseDate: any | null | undefined;
    readonly status: WarrantyStatusEnum;
  } | null | undefined;
};
export type WarrantyProofQuery = {
  response: WarrantyProofQuery$data;
  variables: WarrantyProofQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "warrantyId",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "instrumentId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "purchaseDate",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "proofOfPurchaseFileName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "countryCodeAlpha2",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "industrySectorName",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "modelName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WarrantyProofQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Warranty",
        "kind": "LinkedField",
        "name": "warranty",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Instrument",
            "kind": "LinkedField",
            "name": "instrument",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WarrantyProofQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Warranty",
        "kind": "LinkedField",
        "name": "warranty",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Instrument",
            "kind": "LinkedField",
            "name": "instrument",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f393af0f21197da4227c449f51921435",
    "id": null,
    "metadata": {},
    "name": "WarrantyProofQuery",
    "operationKind": "query",
    "text": "query WarrantyProofQuery(\n  $id: ID!\n) {\n  warranty(warrantyId: $id) {\n    id\n    instrumentId\n    purchaseDate\n    proofOfPurchaseFileName\n    status\n    email\n    instrument {\n      instrumentId\n      companyName\n      countryCodeAlpha2\n      industrySectorName\n      modelName\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "41e1ceb4b6099038f92f11c3ff818446";

export default node;
