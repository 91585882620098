import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export const SoftwareDownloads: React.FC = () => {
  return (
    <>
      <Typography variant="h1" mt={'2rem'}>
        Software Downloads
      </Typography>
      <Typography variant="body1" mb={'1rem'}>
        Click below to browse all software downloads available.
      </Typography>

      <Button color="primary" component={Link} to="/all-software">
        All Software Downloads
      </Button>
    </>
  );
};
