import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import React from 'react';
import { useLocation } from 'react-router-dom';

export const useAppInsightsPageview = (pageName: string) => {
  const location = useLocation();
  const ai = useAppInsightsContext();

  React.useEffect(
    () => ai.trackPageView({ uri: location.pathname, name: pageName }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname]
  );
};
