import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import { AppBar, Box, Button, Grid, IconButton, Link, Menu, MenuItem, Paper, Toolbar, Tooltip, Typography, useTheme } from '@mui/material';
import React, { Suspense } from 'react';
import { useAuth } from 'react-oidc-context';
import { NavLink, useNavigate } from 'react-router-dom';
import NovacytLogo from '../assets/logo_mono_neg.png';
import { AUTH_URI } from '../environment-variables';
import { AppInsightsCookie } from '../services/app-insights/useAppInsightsCookie';
import { Role } from '../services/authentication/Roles';
import { useRoles } from '../services/authentication/useRoles';
import { PleaseWait } from 'itis-helix-react-core';
import GraphQLErrorBoundary from '../components/GraphqlErrorBoundary';
import { useAllRoles } from '../services/authentication/useAllRoles';

interface LayoutProps {
  aiCookie: AppInsightsCookie;
  children: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = ({ aiCookie, children }) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const hasRole = useRoles();
  const allRoles = useAllRoles();
  const theme = useTheme();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const pages = [
    hasRole(Role.Admin) || hasRole(Role.GenesigReleaseManager) ? { name: 'Software', url: '/software-products' } : { name: 'Software', url: '/all-software' },
    { name: 'Dashboard', url: '/dashboard' },
    { name: 'Register Instrument', url: '/register-instrument' }
  ]
    .filter((f) => !!f)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    .map((f) => f!);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      <AppBar color="primary" elevation={0} position="fixed" sx={{ displayPrint: 'none', py: '1rem', px: 0 }}>
        <Toolbar disableGutters>
          {auth.isAuthenticated && (
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton size="large" aria-haspopup="true" onClick={handleOpenNavMenu} color="inherit">
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' }
                }}
                PaperProps={{
                  style: {
                    padding: 0
                  }
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page.name} onClick={handleCloseNavMenu} component={NavLink} to={page.url}>
                    <Typography textAlign="center">{page.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}

          <Box sx={{ display: 'flex', flexGrow: { xs: 1, md: 1 } }}>
            <NavLink to="/">
              <Box component="img" sx={{ width: '12rem', my: '1.4rem', mx: '2rem' }} src={NovacytLogo} alt="Novacyt logo" />
            </NavLink>
          </Box>

          {!auth.isAuthenticated && (
            <Box sx={{ display: 'flex', flexGrow: 0, mr: '2rem' }}>
              <Button
                variant="outlined"
                onClick={async () => await auth.signinRedirect({ state: window.location.pathname })}
                sx={[{ color: '#FFF', borderColor: '#FFF', whiteSpace: 'nowrap' }, { '&:hover': { color: '#AAA', borderColor: '#AAA' } }]}
              >
                Log in
              </Button>
            </Box>
          )}

          {auth.isAuthenticated && (
            <>
              <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
                {pages.map((page) => (
                  <Link
                    key={page.name}
                    component={NavLink}
                    to={page.url}
                    underline="hover"
                    textAlign="center"
                    color="white"
                    sx={{ mx: '1.5rem' }}
                    onClick={handleCloseNavMenu}
                  >
                    {page.name}
                  </Link>
                ))}
              </Box>

              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="My Profile">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, mx: '2rem' }}>
                    <AccountCircleIcon fontSize="large" sx={{ color: '#ffffff' }} />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                  PaperProps={{
                    style: {
                      padding: 0
                    }
                  }}
                >
                  <MenuItem onClick={() => window.location.href = AUTH_URI} sx={{ textTransform: 'none', display: 'block', textAlign: 'left' }}>
                    <PersonIcon sx={{ mr: '1rem', verticalAlign: 'middle' }} />
                    <Box>
                      <Typography variant="body1" sx={{ display: 'inline' }}>{auth.user?.profile.name}</Typography>
                      {allRoles.map((role, index) => (
                        <Typography variant="body2" key={index} sx={{ display: 'block' }}>
                          {role}
                        </Typography>
                      ))}
                    </Box>
                  </MenuItem>

                  <MenuItem onClick={() => navigate('/logging-out')}>
                    <LogoutIcon sx={{ mr: '1rem' }} /> Logout
                  </MenuItem>
                </Menu>
              </Box>
            </>
          )}
        </Toolbar>
      </AppBar>
      <Box sx={{ height: 105, width: '100%' }} />

      <Suspense fallback={<PleaseWait message="Loading..." />}>
        <GraphQLErrorBoundary>
          {children}
        </GraphQLErrorBoundary>
      </Suspense>

      {!aiCookie.isDefined && (
        <Paper
          sx={{
            marginTop: 'calc(10% + 60px)',
            position: 'fixed',
            bottom: 0,
            width: '100%',
            bgcolor: theme.palette.primary.main
          }}
          component="footer"
          square
          variant="outlined"
        >
          <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" mt={1}>
            <Typography color={theme.palette.primary.contrastText}>
              We use cookies to improve your online experience. Some of these cookies are necessary to make the site work. We use other optional
              cookies to monitor the website performance. All our cookie data is anonymised. Please let us know if you agree to these optional
              cookies. Find out more in our{' '}
              <Link color={theme.palette.primary.contrastText} href="/cookies">
                cookie policy
              </Link>
              .
            </Typography>
            <Button
              sx={{ color: theme.palette.primary.contrastText, borderColor: theme.palette.primary.contrastText, m: 1 }}
              onClick={aiCookie.permit}
            >
              Yes, enable performance cookies
            </Button>
            <Button
              variant="contained"
              sx={{ color: theme.palette.primary.contrastText, borderColor: theme.palette.primary.contrastText, m: 1 }}
              onClick={aiCookie.forbid}
            >
              No, don't use optional cookies
            </Button>
          </Grid>
        </Paper>
      )}
    </>
  );
};
