/**
 * @generated SignedSource<<d312d011484bdc17bb148ac21e29cfd9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CurrentReleaseFragment$data = {
  readonly currentVersion: {
    readonly assets: ReadonlyArray<{
      readonly description: string;
      readonly id: string;
      readonly location: string | null | undefined;
      readonly name: string;
      readonly size: string;
      readonly softwareVersionId: string;
      readonly type: string;
    }>;
    readonly id: string;
    readonly lastUpdatedDate: any | null | undefined;
    readonly publishedDate: any;
    readonly releaseNumber: string;
    readonly softwareId: string;
  } | null | undefined;
  readonly " $fragmentType": "CurrentReleaseFragment";
};
export type CurrentReleaseFragment$key = {
  readonly " $data"?: CurrentReleaseFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CurrentReleaseFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CurrentReleaseFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SoftwareVersion",
      "kind": "LinkedField",
      "name": "currentVersion",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "softwareId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "releaseNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "publishedDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastUpdatedDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Asset",
          "kind": "LinkedField",
          "name": "assets",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "size",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "location",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "softwareVersionId",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Software",
  "abstractKey": null
};
})();

(node as any).hash = "c111983eea959fa990684137519febb4";

export default node;
