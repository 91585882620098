import React from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import { useAppInsightsPageview } from '../../services/app-insights/useAppInsightsPageview';
import { PleaseWait } from './PleaseWait';

export const LoggingOut: React.FC = () => {
  useAppInsightsPageview('Logging out');

  const auth = useAuth();
  const navigate = useNavigate();

  React.useEffect(() => {
    (async () => {
      await auth.signoutRedirect();
      navigate('/');
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <PleaseWait message={'Logging out...'} />;
};
