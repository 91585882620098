/**
 * @generated SignedSource<<82dc02a269d750d2170fee607cc5cf9f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type WarrantyStatusEnum = "ACTIVE" | "EXPIRED" | "INIT" | "PENDING" | "%future added value";
export type ApproveWarrantyMutation$variables = {
  commencementDate: any;
  warrantyId: string;
};
export type ApproveWarrantyMutation$data = {
  readonly approveWarranty: {
    readonly errors: ReadonlyArray<{
      readonly __typename: string;
      readonly message?: string;
    }> | null | undefined;
    readonly warranty: {
      readonly commencementDate: any | null | undefined;
      readonly email: string | null | undefined;
      readonly id: string;
      readonly instrumentId: string;
      readonly interactionDate: any | null | undefined;
      readonly proofOfPurchaseFileName: string | null | undefined;
      readonly purchaseDate: any | null | undefined;
      readonly status: WarrantyStatusEnum;
    } | null | undefined;
  };
};
export type ApproveWarrantyMutation = {
  response: ApproveWarrantyMutation$data;
  variables: ApproveWarrantyMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "commencementDate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "warrantyId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "commencementDate",
            "variableName": "commencementDate"
          },
          {
            "kind": "Variable",
            "name": "warrantyId",
            "variableName": "warrantyId"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "ApproveWarrantyPayload",
    "kind": "LinkedField",
    "name": "approveWarranty",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Warranty",
        "kind": "LinkedField",
        "name": "warranty",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "purchaseDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "proofOfPurchaseFileName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "instrumentId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "commencementDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "interactionDate",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "type": "Error",
            "abstractKey": "__isError"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ApproveWarrantyMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ApproveWarrantyMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "6911227e9a505e1fa3a74207a8cb3c55",
    "id": null,
    "metadata": {},
    "name": "ApproveWarrantyMutation",
    "operationKind": "mutation",
    "text": "mutation ApproveWarrantyMutation(\n  $warrantyId: ID!\n  $commencementDate: DateTime!\n) {\n  approveWarranty(input: {warrantyId: $warrantyId, commencementDate: $commencementDate}) {\n    warranty {\n      id\n      purchaseDate\n      proofOfPurchaseFileName\n      status\n      instrumentId\n      email\n      commencementDate\n      interactionDate\n    }\n    errors {\n      __typename\n      ... on Error {\n        __isError: __typename\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "952b8682e782b43ed0ac637979506899";

export default node;
