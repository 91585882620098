/**
 * @generated SignedSource<<e5bd687b4d9b9653ff05cd6e1842f1fa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PublishReleaseMutation$variables = {
  productCode: string;
};
export type PublishReleaseMutation$data = {
  readonly publishNewRelease: {
    readonly errors: ReadonlyArray<{
      readonly __typename: string;
      readonly message?: string;
    }> | null | undefined;
    readonly softwareVersion: {
      readonly assets: ReadonlyArray<{
        readonly description: string;
        readonly id: string;
        readonly location: string | null | undefined;
        readonly name: string;
        readonly size: string;
        readonly softwareVersionId: string;
        readonly type: string;
      }>;
      readonly id: string;
      readonly lastUpdatedDate: any | null | undefined;
      readonly publishedDate: any;
      readonly releaseNumber: string;
      readonly softwareId: string;
    } | null | undefined;
  };
};
export type PublishReleaseMutation = {
  response: PublishReleaseMutation$data;
  variables: PublishReleaseMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "productCode"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "productCode",
            "variableName": "productCode"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "PublishNewReleasePayload",
    "kind": "LinkedField",
    "name": "publishNewRelease",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SoftwareVersion",
        "kind": "LinkedField",
        "name": "softwareVersion",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "softwareId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "releaseNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "publishedDate",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastUpdatedDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Asset",
            "kind": "LinkedField",
            "name": "assets",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "size",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "location",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "softwareVersionId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "type": "Error",
            "abstractKey": "__isError"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PublishReleaseMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PublishReleaseMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "2b0b62ec61597805f24d6058f81f6243",
    "id": null,
    "metadata": {},
    "name": "PublishReleaseMutation",
    "operationKind": "mutation",
    "text": "mutation PublishReleaseMutation(\n  $productCode: ID!\n) {\n  publishNewRelease(input: {productCode: $productCode}) {\n    softwareVersion {\n      softwareId\n      releaseNumber\n      publishedDate\n      id\n      lastUpdatedDate\n      assets {\n        id\n        name\n        description\n        type\n        size\n        location\n        softwareVersionId\n      }\n    }\n    errors {\n      __typename\n      ... on Error {\n        __isError: __typename\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f8bc874b01ee06ce5c572a9f960967df";

export default node;
