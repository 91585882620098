import React from 'react';
import ProofDocument from './ProofDocument';
import { graphql } from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay';
import { Grid } from '@mui/material';
import ApproveWarranty from './ApproveWarranty';
import { WarrantyProofQuery } from './__generated__/WarrantyProofQuery.graphql';
import WarrantyDetails from './WarrantyDetails';

const query = graphql`
query WarrantyProofQuery($id: ID!) {
    warranty(warrantyId: $id) {
      id
      instrumentId
       purchaseDate
       proofOfPurchaseFileName
       status
       email
       instrument {
        instrumentId
        companyName
        countryCodeAlpha2
        industrySectorName
        modelName
       }
    }
  }
`;

export interface WarrantyProofProps {
  warrantyId: string;
}

const WarrantyProof: React.FC<WarrantyProofProps> = ({ warrantyId }) => {
  const data = useLazyLoadQuery<WarrantyProofQuery>(query, { id: warrantyId });

  const instrumentId = data.warranty?.instrument?.instrumentId;
  const filename = data.warranty?.proofOfPurchaseFileName;

  return (
    <>
      <Grid item xs={12} md={6}>
        <WarrantyDetails warranty={data.warranty} />
        {data.warranty?.id && <ApproveWarranty warrantyId={data.warranty.id} />}
      </Grid>
      <Grid item xs={12} md={6}>
        {instrumentId && filename && <ProofDocument instrumentId={instrumentId} imageName={filename} />}
      </Grid>
    </>
  );
};

export default WarrantyProof;