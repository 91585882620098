import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import React from 'react';
import { InstrumentGroup } from '../../dtos/Instrument';
import { getImageFromArray } from '../../helpers/ImageSelector';
import { SoftwareDownloadDialog } from '../software/SoftwareDownloadDialog';

interface InstrumentCardProps {
  instrumentGroup: InstrumentGroup;
}

export const InstrumentCard: React.FC<InstrumentCardProps> = ({ instrumentGroup }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      <Card sx={{ p: 0 }}>
        <CardMedia component="img" image={getImageFromArray(instrumentGroup.uniqueCodes)} width="100%" />
        <CardContent>
          <Typography variant="body1" sx={{ fontWeight: 'medium' }}>
            {instrumentGroup.modelName}
          </Typography>
          <Typography>{instrumentGroup.count} Instruments Registered</Typography>
        </CardContent>
        <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
          {instrumentGroup.softwareAssets.length > 0 && <Button
            color="primary"
            onClick={() => {
              setIsOpen(true);
            }}
          >
            Download software
          </Button>}
        </CardActions>
      </Card>

      <SoftwareDownloadDialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title={instrumentGroup.modelName}
        assets={instrumentGroup.softwareAssets}
        releaseVersion={instrumentGroup.releaseVersion}
      />
    </>
  );
};
