import React, { useEffect, useState } from 'react';
import { API_URI } from '../../environment-variables';
import { useAuth } from 'react-oidc-context';
import { Grid, Paper, lighten, useTheme } from '@mui/material';

export interface ProofDocumentProps {
  instrumentId: string;
  imageName: string;
}

const ProofDocument: React.FC<ProofDocumentProps> = ({ instrumentId, imageName }) => {
  const auth = useAuth();
  const theme = useTheme();
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  useEffect(() => {
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${auth.user?.access_token}`);
    fetch(`${API_URI}/file/pop/${instrumentId}/${imageName}`, { headers })
      .then(response => {
        if (response.ok) return response.blob();

        throw new Error('Error downloading image');
      })
      .then(blob => {
        const imageUrl = URL.createObjectURL(blob);
        setImageSrc(imageUrl);
      })
      .catch(error => console.log('Error downloading image: ', error));

    return () => {
      if (imageSrc) URL.revokeObjectURL(imageSrc);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instrumentId]);

  return (
    <>
      <Paper sx={{ mt: '1rem', backgroundColor: lighten(theme.palette.secondary.light, 0.7), color: theme.palette.secondary.contrastText }}>
        <Grid item>
          {imageSrc && <img width={'100%'} src={imageSrc} alt='Download Image' />}
        </Grid>
      </Paper>
    </>
  );
};

export default ProofDocument;