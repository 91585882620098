import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useSnackbar } from 'notistack';
import { PayloadError } from 'relay-runtime';

const useMutationResponseHandler = () => {
  const { enqueueSnackbar } = useSnackbar();
  const ai = useAppInsightsContext();
  const appInsights = ai.getAppInsights();

  const responseHandler = {
    // eslint-disable-next-line max-params
    handleSuccess: (
      payloadErrors: PayloadError[] | null,
      errors: readonly {
        readonly __typename: string;
        readonly message?: string | undefined;
      }[] | null | undefined,
      doOnSuccess: (() => void)[],
      doOnError: (() => void)[]
    ) => {

      payloadErrors && appInsights.trackException({
        error: new Error(`API error: ${JSON.stringify(payloadErrors)}`)
      });


      errors && errors.length > 0 && appInsights.trackException({
        error: new Error(`API error: ${JSON.stringify(payloadErrors)}`)
      });

      if (!payloadErrors && !errors) {
        doOnSuccess.forEach(func => func());
      } else {
        doOnError.forEach(func => func());
      }
    },

    handleError: (error: Error, errorMessage: string) => {
      appInsights.trackException({
        error: new Error(`API error, ${JSON.stringify(error)}`)
      });
      enqueueSnackbar(errorMessage, {
        variant: 'error'
      });
    }
  };

  return { responseHandler };
};

export { useMutationResponseHandler };