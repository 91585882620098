import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import DocumentDownloadButton from './DocumentDownloadButton';
import { graphql } from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay';
import { AllDocumentsModalQuery } from './__generated__/AllDocumentsModalQuery.graphql';
import { DocumentType } from './__generated__/AllDocumentsModalQuery.graphql';

const allDocumentsModalQuery = graphql`
  query AllDocumentsModalQuery($instrumentId: String!) {
    allDocumentsForInstrument(instrumentId: $instrumentId) {
      ... on GetAllDocumentsForInstrument_AllDocumentsForInstrument_Document {
        documentType
        fileName
      }
    }
  }
`;

interface AllDocumentsModalProps {
  open: boolean;
  onClose: () => void;
  instrumentId: string;
}



const AllDocumentsModal: React.FC<AllDocumentsModalProps> = ({ open, onClose, instrumentId }) => {
  const data = useLazyLoadQuery<AllDocumentsModalQuery>(allDocumentsModalQuery, { instrumentId }, { fetchPolicy: 'network-only' });

  const documents = data.allDocumentsForInstrument || [];

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Instrument: {instrumentId}</DialogTitle>
      <DialogContent>
        {documents.length > 0 ? (
          <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
            {documents.map((doc, index) => (
              <DocumentDownloadButton
                key={index}
                instrumentId={instrumentId}
                fileName={doc.fileName}
                documentType={doc.documentType as DocumentType}
                showDownloadButton={true}
              />
            ))}
          </Box>
        ) : (
          <Typography variant="body1" align="center">
            No documents available for this instrument.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AllDocumentsModal;