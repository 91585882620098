import React from 'react';
import { useMutation } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { PayloadError } from 'relay-runtime';
import { useMutationResponseHandler } from '../../services/app-insights/useMutationResponseHandler';
import { DownloadAssetMutation } from './__generated__/DownloadAssetMutation.graphql';
import { Asset } from '../../dtos/Software';
import Button from '@mui/material/Button';

interface DownloadAssetProps {
  asset: Asset;
}

const assetMutation = graphql`
  mutation DownloadAssetMutation($assetId: ID!) {
    assetBlobUrlForDownload(input: { assetId: $assetId }) {
      string
      errors {
        __typename
        ... on Error {
          message
        }
      }
    }
  }
`;

const DownloadAsset: React.FC<DownloadAssetProps> = ({ asset }) => {
  const { responseHandler } = useMutationResponseHandler();
  const [getAssetDownloadUrl] = useMutation<DownloadAssetMutation>(assetMutation);

  const handleCompleted = (response: DownloadAssetMutation['response'], errors: PayloadError[] | null) => {
    responseHandler.handleSuccess(
      errors,
      response?.assetBlobUrlForDownload?.errors,
      [() => {
        if (response?.assetBlobUrlForDownload?.string) {
          downloadFile(response?.assetBlobUrlForDownload?.string);
        }
      }],
      []
    );
  };

  const handleError = (error: Error) => {
    responseHandler.handleError(error, 'An error occurred downloading the asset');
  };

  const downloadFile = (url: string) => {
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = asset.name;
    anchor.click();
  };

  const handleDownload = () => {
    getAssetDownloadUrl({
      variables: {
        assetId: asset.id
      },
      onCompleted: handleCompleted,
      onError: handleError
    });
  };

  return (
    <Button color="primary"
      onClick={handleDownload}
      href={asset.location}
      download={asset.name}
      fullWidth
      sx={{ marginTop: '1rem' }}
    >Download {asset.name}
    </Button>
  );
};

export default DownloadAsset;
