
import Markdown from '../../../components/Markdown';
import preRelease from '../../../assets/guides/software-release/no-release-small.jpg';
import firstRelease from '../../../assets/guides/software-release/first-release-small.jpg';
import secondRelease from '../../../assets/guides/software-release/second-release-small.jpg';
import removedVersion from '../../../assets/guides/software-release/removed-version-small.jpg';
import assetList from '../../../assets/guides/software-release/asset-list-small.jpg';
import uploadAsset from '../../../assets/guides/software-release/upload-new-asset-small.jpg';
import finalAssetList from '../../../assets/guides/software-release/final-asset-list-small.jpg';

const GenesigSoftwareReleaseGuidePage: React.FC = () => {

  const title = 'Software Release User Guide';

  const markdown = `
  This guide provides details of the screens that support the release of software versions for the genesig range of instruments to facilitate downloading by end users

  **Please note, only software versions that have completed quality checks will be available to be released by genesig release managers with appropriate system permissions**
  <br><br>
  <span style="font-size: larger">**1. Generate a software release when no previous releases have happened**</span>
  <br><br>
  This screen indicates that currently there is no version available for end users to download.
  <br><br>
  Click **'PUBLISH NEW RELEASE'** button to start the process of releasing the indicated version.
  <br><br>
  (The view will change to indicate that process is running and the display will indicate when the release has either completed or failed)
  <br><br>
  ![no release](${preRelease})
  <br><br>
  <span style="font-size: larger">**2. Current Release**</span>
  <br><br>
  The example screen is indicating that the 'Current Release' available for users to download matches the latest version that has been marked as a release candidate by the Software Team. 
  <br><br>
  ![first release](${firstRelease})
  <br><br>
  <span style="font-size: larger">**3. Remove a version**</span>
  <br><br>
  When a version has been previously released but needs withdrawing, the **'REMOVE VERSION'** button can be used to revert back to the previous version
  <br><br>
  ![new release](${secondRelease})
  <br><br>
  **The following image shows that the current release has dropped back to the previously available version**
  <br><br>
  ![remove version](${removedVersion})
  <br><br>
  <span style="font-size: larger">**4. Add a new asset to the release**</span>
  <br><br>
  Additional assets can be uploaded if required by using **UPLOAD NEW ASSET**
  <br><br>
  ![asset list](${assetList})
  <br><br>
  **Provide a description and select the additional asset that is to be included in the release**
  <br><br>
  ![upload asset](${uploadAsset})
  <br><br>
  **The new asset is listed to be included in the release. Deleting an asset from the release will remove it permanently, but can be added using the same method**
  <br><br>
  ![final asset](${finalAssetList})
  `;

  return (
    <div>
      <Markdown title={title} markdown={markdown}/>
    </div>
  );
};

export default GenesigSoftwareReleaseGuidePage;