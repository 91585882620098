import { CircularProgress, Grid, Typography } from '@mui/material';
import { useAppInsightsPageview } from '../../services/app-insights/useAppInsightsPageview';

export const PleaseWait: React.FC<{ message: string }> = ({ message }) => {
  useAppInsightsPageview('Please wait');

  return (
    <Grid container sx={{ marginTop: '2rem', marginBottom: '2rem' }}>
      <Grid item xs={12} sx={{ margin: 'auto' }}>
        <Typography sx={{ textAlign: 'center' }} variant="h2">
          <CircularProgress sx={{ marginRight: '2rem', marginTop: '4rem' }} />
          {message}
        </Typography>
      </Grid>
    </Grid>
  );
};
