import { CardMembership } from '@mui/icons-material';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import { isInWarranty } from './Warranty';
import { WarrantyStatusEnum } from '../warranty/__generated__/ApproveWarrantyMutation.graphql';
import WarrantyProofUpload from '../warranty/WarrantyProofUpload';
import { useMemo, useState } from 'react';
import { RecordSourceSelectorProxy } from 'relay-runtime';
import { linkRecord } from 'itis-helix-react-core';
import { useSubscription } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';


interface WarrantyCellProps {
  commencementDate: Date;
  showIcon: boolean;
  warrantyStatus: WarrantyStatusEnum | undefined;
  instrumentId: string;
  warrantyId: string | undefined;
  customerId: string;
}

const subscription = graphql`
  subscription WarrantyCellSubscription($customerId: ID) {
    warrantyUpdated(customerId: $customerId) {
      id
      status
      commencementDate
    }
  }
`;

const daysLeft = (certified: Date): string => {
  const currentDate = new Date();
  const certifiedDate = new Date(certified);
  const daysDifference = (currentDate.getTime() - certifiedDate.getTime()) / (1000 * 60 * 60 * 24);
  const remaining = 730 - Math.round(daysDifference);
  return `${remaining} day${(remaining > 1 ? 's' : '')} remaining`;
};

const warrantyStatusText = (certified: Date): string => {
  return isInWarranty(certified)
    ? `Within warranty period, ${daysLeft(certified)}`
    : 'Out of warranty, please contact customer services';
};

const warrantyStatusColor = (commencementDate: Date): string => {
  return isInWarranty(commencementDate) ? 'blue' : 'red';
};

const warrantyDisplay = (commencementDate: Date, warrantyStatus?: WarrantyStatusEnum): string => {
  if (warrantyStatus === 'PENDING') return 'Pending';
  if (warrantyStatus === 'ACTIVE' && isInWarranty(commencementDate)) return warrantyStatusText(commencementDate);
  return warrantyStatusText(commencementDate);
};

const WarrantyCell: React.FC<WarrantyCellProps> = ({ commencementDate, showIcon, warrantyStatus, instrumentId, warrantyId, customerId }) => {

  const warrantySubscription = useMemo(() => ({
    variables: { customerId },
    subscription
  }), [customerId]);

  useSubscription(warrantySubscription);

  return (
    <>
      {!warrantyStatus
        ? <WarrantyProofUpload showIcon instrumentId={instrumentId} />
        : showIcon
          ? (
            <TableCell style={{ color: warrantyStatusColor(commencementDate), fontWeight: 'bold' }}>
              {warrantyDisplay(commencementDate, warrantyStatus)}
            </TableCell>
          )
          : (
            <TableCell>
              <Tooltip title={warrantyDisplay(commencementDate, warrantyStatus)}>
                <CardMembership />
              </Tooltip>
            </TableCell>
          )
      }
    </>
  );
};
export default WarrantyCell;