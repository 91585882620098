import React from 'react';
import { useMemo } from 'react';
import { useAuth } from 'react-oidc-context';
import { RelayEnvironmentProvider } from 'react-relay/hooks';
import {
  Environment,
  GraphQLResponse,
  Network,
  Observable,
  RecordSource,
  RequestParameters,
  Store,
  Variables
} from 'relay-runtime';
import { GRAPHQL_URI } from '../environment-variables';
import { SUBSCRIPTION_URI } from '../environment-variables';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Client, createClient } from 'graphql-ws';

const url = GRAPHQL_URI;

const store = new Store(new RecordSource());

type NovacytRelayEnvironmentProviderProps = {
  children: React.ReactNode;
  appInsights: ApplicationInsights;
}


/**
 * Provides a Relay Environment using RelayEnvironmentProvider.
 * This environment uses a singleton Store for caching, and
 * recreates the Network whenever the access token from `useAuth` changes.
 * This allows us to pass the access token with each graphql request.
 * As an alternative, we could use a singleton Environment that
 * retrieves the token directly from local or session storage,
 * see https://www.npmjs.com/package/react-oidc-context
 * However this would be more fragile since it would need to
 * know where the token is stored, which depends on the `oidcConfig`
 * in `NovacytAuthProvider.tsx`.
 */
const NovacytRelayEnvironmentProvider = ({ children, appInsights }: NovacytRelayEnvironmentProviderProps) => {

  const auth = useAuth();

  const token = auth.user?.access_token;

  const env = useMemo(() => {
    const fetchQuery = (
      operation: RequestParameters,
      variables: Variables,
    ) => {
      return fetch(url, {
        method: 'POST',
        headers: {
          'Authorization': `bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: operation.text,
          variables,
        }),
      }).then(response => {
        return response.json();
      }).catch(error => {
        appInsights.trackException({
          error: new Error(`API Hook threw error ${error?.name} with message ${error?.message} and stack ${error?.stack}.`)
        });
      });
    };

    const subscriptionClient: Client = createClient({
      url: SUBSCRIPTION_URI,
      connectionParams: () => ({
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    });

    const subscribe = (operation: RequestParameters, variables: Record<string, unknown> | null): Observable<GraphQLResponse> => {
      return Observable.create((sink) => {
        const unsubscribe = subscriptionClient.subscribe(
          {
            operationName: operation.name,
            query: operation.text ?? '',
            variables
          },
          {
            ...sink,
            next: (value) => {
              sink.next(value as GraphQLResponse);
            }
          }
        );
        return () => {
          unsubscribe();
        };
      });
    };

    return new Environment({
      network: Network.create(fetchQuery, subscribe),
      store: store,
    });
  }, [token, appInsights]);

  return(
    <RelayEnvironmentProvider environment={env}>
      {children}
    </RelayEnvironmentProvider>
  );
};

export default NovacytRelayEnvironmentProvider;