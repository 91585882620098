import { FC } from 'react';
import { Role } from '../../services/authentication/Roles';
import { SecureComponent } from './SecureComponent';

interface SecurePageProps {
  permittedRoles?: Role[];
  children: React.ReactNode;
}

export const SecurePage: FC<SecurePageProps> = ({ permittedRoles, children }) => (
  <SecureComponent permittedRoles={permittedRoles} forceLogin>
    {children}
  </SecureComponent>
);
