/**
 * @generated SignedSource<<e6db96be7cd10d15356c2fd2e591d67a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ManageSoftwareGetSoftwareQuery$variables = {
  id: string;
};
export type ManageSoftwareGetSoftwareQuery$data = {
  readonly node: {
    readonly currentVersionId?: string | null | undefined;
    readonly etag?: string;
    readonly id?: string;
    readonly name?: string;
    readonly versions?: ReadonlyArray<{
      readonly id: string;
      readonly lastUpdatedDate: any | null | undefined;
      readonly publishedDate: any;
      readonly releaseNumber: string;
      readonly softwareId: string;
    }>;
    readonly " $fragmentSpreads": FragmentRefs<"CurrentReleaseFragment" | "LatestReleaseFragment">;
  } | null | undefined;
};
export type ManageSoftwareGetSoftwareQuery = {
  response: ManageSoftwareGetSoftwareQuery$data;
  variables: ManageSoftwareGetSoftwareQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "etag",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currentVersionId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "softwareId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "releaseNumber",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "publishedDate",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastUpdatedDate",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "SoftwareVersion",
  "kind": "LinkedField",
  "name": "versions",
  "plural": true,
  "selections": [
    (v6/*: any*/),
    (v7/*: any*/),
    (v8/*: any*/),
    (v2/*: any*/),
    (v9/*: any*/)
  ],
  "storageKey": null
},
v11 = [
  (v2/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/),
  (v8/*: any*/),
  (v9/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Asset",
    "kind": "LinkedField",
    "name": "assets",
    "plural": true,
    "selections": [
      (v2/*: any*/),
      (v4/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "size",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "location",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "softwareVersionId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ManageSoftwareGetSoftwareQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CurrentReleaseFragment"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "LatestReleaseFragment"
              },
              (v10/*: any*/)
            ],
            "type": "Software",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ManageSoftwareGetSoftwareQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SoftwareVersion",
                "kind": "LinkedField",
                "name": "currentVersion",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SoftwareVersion",
                "kind": "LinkedField",
                "name": "latestRelease",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": null
              },
              (v10/*: any*/)
            ],
            "type": "Software",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "adb99b569fad9c4048c54afebc31d6d7",
    "id": null,
    "metadata": {},
    "name": "ManageSoftwareGetSoftwareQuery",
    "operationKind": "query",
    "text": "query ManageSoftwareGetSoftwareQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on Software {\n      id\n      etag\n      name\n      currentVersionId\n      ...CurrentReleaseFragment\n      ...LatestReleaseFragment\n      versions {\n        softwareId\n        releaseNumber\n        publishedDate\n        id\n        lastUpdatedDate\n      }\n    }\n    id\n  }\n}\n\nfragment CurrentReleaseFragment on Software {\n  currentVersion {\n    id\n    softwareId\n    releaseNumber\n    publishedDate\n    lastUpdatedDate\n    assets {\n      id\n      name\n      description\n      type\n      size\n      location\n      softwareVersionId\n    }\n  }\n}\n\nfragment LatestReleaseFragment on Software {\n  latestRelease {\n    id\n    softwareId\n    releaseNumber\n    publishedDate\n    lastUpdatedDate\n    assets {\n      id\n      name\n      description\n      type\n      size\n      location\n      softwareVersionId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5434cb6a6b89cf1fd166d2e2e1cb7f94";

export default node;
