/**
 * @generated SignedSource<<61389c5a40c5e9ac821fe57903105fd0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SoftwareAssetsGetSoftwareQuery$variables = {
  id: string;
};
export type SoftwareAssetsGetSoftwareQuery$data = {
  readonly software: {
    readonly currentVersion: {
      readonly assets: ReadonlyArray<{
        readonly description: string;
        readonly id: string;
        readonly location: string | null | undefined;
        readonly name: string;
        readonly size: string;
        readonly softwareVersionId: string;
        readonly type: string;
      }>;
      readonly id: string;
      readonly lastUpdatedDate: any | null | undefined;
      readonly publishedDate: any;
      readonly releaseNumber: string;
      readonly softwareId: string;
    } | null | undefined;
    readonly etag: string;
    readonly id: string;
    readonly name: string;
    readonly versions: ReadonlyArray<{
      readonly id: string;
      readonly lastUpdatedDate: any | null | undefined;
      readonly publishedDate: any;
      readonly releaseNumber: string;
      readonly softwareId: string;
    }>;
  } | null | undefined;
};
export type SoftwareAssetsGetSoftwareQuery = {
  response: SoftwareAssetsGetSoftwareQuery$data;
  variables: SoftwareAssetsGetSoftwareQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "softwareId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "releaseNumber",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "publishedDate",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastUpdatedDate",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "productCode",
        "variableName": "id"
      }
    ],
    "concreteType": "Software",
    "kind": "LinkedField",
    "name": "software",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "etag",
        "storageKey": null
      },
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "SoftwareVersion",
        "kind": "LinkedField",
        "name": "currentVersion",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Asset",
            "kind": "LinkedField",
            "name": "assets",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "size",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "location",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "softwareVersionId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "SoftwareVersion",
        "kind": "LinkedField",
        "name": "versions",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v1/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SoftwareAssetsGetSoftwareQuery",
    "selections": (v7/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SoftwareAssetsGetSoftwareQuery",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "1b8186d8e4f60d98e35a425a847bcb16",
    "id": null,
    "metadata": {},
    "name": "SoftwareAssetsGetSoftwareQuery",
    "operationKind": "query",
    "text": "query SoftwareAssetsGetSoftwareQuery(\n  $id: ID!\n) {\n  software(productCode: $id) {\n    id\n    etag\n    name\n    currentVersion {\n      id\n      softwareId\n      releaseNumber\n      publishedDate\n      lastUpdatedDate\n      assets {\n        id\n        name\n        description\n        type\n        size\n        location\n        softwareVersionId\n      }\n    }\n    versions {\n      softwareId\n      releaseNumber\n      publishedDate\n      id\n      lastUpdatedDate\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "18b02cb1fd788d074162b6ed8b9dbee8";

export default node;
