import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import { toDateFormat } from '../../helpers/DateHelper';
import { CurrentReleaseFragment$data } from './__generated__/CurrentReleaseFragment.graphql';

interface SoftwareReleaseContentProps {
  version: CurrentReleaseFragment$data['currentVersion'];
  errorMessage?: string;
  primary?: boolean;
}

export const SoftwareReleaseContent: React.FC<SoftwareReleaseContentProps> = ({ version, errorMessage, primary = false }) => {
  return (
    <CardContent>
      {!version ? (
        <Typography>{errorMessage ?? 'Release Not Found'}</Typography>
      ) : (
        <>
          <Typography variant="body1" pb="0.5rem">
            Release: {version.releaseNumber}
          </Typography>
          <Typography variant="body1" pb="0.5rem">
            Published: {toDateFormat(version.publishedDate)}
          </Typography>

          <List dense subheader="Files">
            {version.assets.map((asset) => (
              <ListItem key={asset.id}>
                <ListItemIcon>
                  <FolderIcon fontSize="small" sx={primary ? { color: '#f0f0f0' } : null} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="body2">
                      {asset.type}: "{asset.description}"
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </>
      )}
    </CardContent>
  );
};
