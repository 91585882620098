/**
 * @generated SignedSource<<3c119b977c6b20c3caa89430ef45894d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type WarrantyStatusEnum = "ACTIVE" | "EXPIRED" | "INIT" | "PENDING" | "%future added value";
export type MyInstrumentsQuery$variables = Record<PropertyKey, never>;
export type MyInstrumentsQuery$data = {
  readonly myInstruments: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly companyName: string;
        readonly countryCodeAlpha2: string;
        readonly customerId: string;
        readonly id: string;
        readonly industrySectorName: string;
        readonly installationDate: any;
        readonly instrumentId: string;
        readonly modelName: string;
        readonly serial: string;
        readonly uniqueCode: string;
        readonly warranties: ReadonlyArray<{
          readonly commencementDate: any | null | undefined;
          readonly id: string;
          readonly instrumentId: string;
          readonly status: WarrantyStatusEnum;
        } | null | undefined>;
      };
    }>;
  };
};
export type MyInstrumentsQuery = {
  response: MyInstrumentsQuery$data;
  variables: MyInstrumentsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "customerId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "instrumentId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "modelName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uniqueCode",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serial",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "countryCodeAlpha2",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "industrySectorName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "installationDate",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "Warranty",
  "kind": "LinkedField",
  "name": "warranties",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commencementDate",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MyInstrumentsQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": null,
          "concreteType": "MyInstrumentsConnection",
          "kind": "LinkedField",
          "name": "myInstruments",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": {
                "alias": null,
                "args": null,
                "concreteType": "MyInstrumentsEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": {
                      "alias": null,
                      "args": null,
                      "concreteType": "Instrument",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "kind": "RequiredField",
                          "field": (v1/*: any*/),
                          "action": "THROW",
                          "path": "myInstruments.edges.node.customerId"
                        },
                        {
                          "kind": "RequiredField",
                          "field": (v2/*: any*/),
                          "action": "THROW",
                          "path": "myInstruments.edges.node.instrumentId"
                        },
                        {
                          "kind": "RequiredField",
                          "field": (v3/*: any*/),
                          "action": "THROW",
                          "path": "myInstruments.edges.node.modelName"
                        },
                        {
                          "kind": "RequiredField",
                          "field": (v4/*: any*/),
                          "action": "THROW",
                          "path": "myInstruments.edges.node.uniqueCode"
                        },
                        {
                          "kind": "RequiredField",
                          "field": (v5/*: any*/),
                          "action": "THROW",
                          "path": "myInstruments.edges.node.serial"
                        },
                        (v6/*: any*/),
                        (v7/*: any*/),
                        (v8/*: any*/),
                        {
                          "kind": "RequiredField",
                          "field": (v9/*: any*/),
                          "action": "THROW",
                          "path": "myInstruments.edges.node.installationDate"
                        },
                        (v10/*: any*/)
                      ],
                      "storageKey": null
                    },
                    "action": "THROW",
                    "path": "myInstruments.edges.node"
                  }
                ],
                "storageKey": null
              },
              "action": "THROW",
              "path": "myInstruments.edges"
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "myInstruments"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "MyInstrumentsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MyInstrumentsConnection",
        "kind": "LinkedField",
        "name": "myInstruments",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MyInstrumentsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Instrument",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a5398d2216e8e4c854aa0df99b923efb",
    "id": null,
    "metadata": {},
    "name": "MyInstrumentsQuery",
    "operationKind": "query",
    "text": "query MyInstrumentsQuery {\n  myInstruments {\n    edges {\n      node {\n        id\n        customerId\n        instrumentId\n        modelName\n        uniqueCode\n        serial\n        companyName\n        countryCodeAlpha2\n        industrySectorName\n        installationDate\n        warranties {\n          id\n          instrumentId\n          status\n          commencementDate\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8765e49715bcc03e6fb75f3b75744c53";

export default node;
