import React from 'react';
import { WarrantyProofQuery$data } from './__generated__/WarrantyProofQuery.graphql';
import { Paper, Typography, useTheme } from '@mui/material';
import { formatDate } from 'itis-helix-react-core';

export type Warranty = WarrantyProofQuery$data['warranty'];

export interface WarrantyDetailsProps {
  warranty: Warranty;
}

const WarrantyDetails: React.FC<WarrantyDetailsProps> = ({ warranty }) => {
  const theme = useTheme();
  return (
    <>
      {warranty &&
        <Paper sx={{ mt: '1rem', backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText }}>
          <Typography variant='h1'>Instrument ID: {warranty.instrument?.instrumentId}</Typography>
          <Typography>Model: {warranty.instrument?.modelName}</Typography>
          <Typography>Company: {warranty.instrument?.companyName}</Typography>
          <Typography>Sector: {warranty.instrument?.industrySectorName}</Typography>
          <Typography>Purchase Date: {formatDate(warranty.purchaseDate)}</Typography>
        </Paper>
      }
    </>
  );
};

export default WarrantyDetails;