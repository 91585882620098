/**
 * @generated SignedSource<<7198c24ac0ddabad45d7399a51586bf6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EnquireRegisterInstrumentMutation$variables = {
  referenceId: string;
};
export type EnquireRegisterInstrumentMutation$data = {
  readonly manufacturedInstrumentByReference: {
    readonly errors: ReadonlyArray<{
      readonly __typename: string;
      readonly message?: string;
    }> | null | undefined;
    readonly manufacturedInstrument: {
      readonly instrumentId: string;
      readonly modelName: string;
      readonly serial: string | null | undefined;
      readonly uniqueCode: string;
    } | null | undefined;
  };
};
export type EnquireRegisterInstrumentMutation = {
  response: EnquireRegisterInstrumentMutation$data;
  variables: EnquireRegisterInstrumentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "referenceId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "referenceId",
            "variableName": "referenceId"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "ManufacturedInstrumentByReferencePayload",
    "kind": "LinkedField",
    "name": "manufacturedInstrumentByReference",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ManufacturedInstrument",
        "kind": "LinkedField",
        "name": "manufacturedInstrument",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "instrumentId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "serial",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "modelName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "uniqueCode",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "type": "Error",
            "abstractKey": "__isError"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EnquireRegisterInstrumentMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EnquireRegisterInstrumentMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "662b358d3af1fbdcf10b100b0cee933b",
    "id": null,
    "metadata": {},
    "name": "EnquireRegisterInstrumentMutation",
    "operationKind": "mutation",
    "text": "mutation EnquireRegisterInstrumentMutation(\n  $referenceId: String!\n) {\n  manufacturedInstrumentByReference(input: {referenceId: $referenceId}) {\n    manufacturedInstrument {\n      instrumentId\n      serial\n      modelName\n      uniqueCode\n    }\n    errors {\n      __typename\n      ... on Error {\n        __isError: __typename\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d53624da04ae2d0f9c8a801069c9a4b1";

export default node;
