import React from 'react';
import { Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { graphql } from 'babel-plugin-relay/macro';
import { PayloadError } from 'relay-runtime';
import { useSnackbar } from 'notistack';
import { useConfirm } from 'material-ui-confirm';
import { useMutation } from 'react-relay';
import { DeleteAssetMutation, DeleteAssetMutation$data } from './__generated__/DeleteAssetMutation.graphql';
import { useMutationResponseHandler } from '../../services/app-insights/useMutationResponseHandler';

const deleteAssetMutation = graphql`
  mutation DeleteAssetMutation($productCode: ID!, $versionId: ID!, $assetId: ID!) {
    deleteSoftwareAsset(input: { productCode: $productCode, versionId: $versionId, assetId: $assetId }) {
      software {
        id
        etag
        name
        supportedInstruments
        versions {
          softwareId
          releaseNumber
          publishedDate
          id
          lastUpdatedDate
        }
        currentVersion {
          softwareId
          releaseNumber
          publishedDate
          id
          lastUpdatedDate
          assets {
            softwareVersionId
            name
            description
            type
            size
            id
            location
          }
        }
      }
      errors {
        __typename
        ... on Error {
          message
        }
      }
    }
  }
`;

export interface DeleteAssetProps {
  softwareId: string;
  versionId: string;
  assetId: string;
}

const DeleteAsset: React.FC<DeleteAssetProps> = ({ softwareId, versionId, assetId }) => {
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const { responseHandler } = useMutationResponseHandler();

  const [commitMutation, IsMutationInFlight] = useMutation<DeleteAssetMutation>(deleteAssetMutation);

  const handleDeleteAsset = () => {
    confirm({ title: 'Are you sure you want to remove this asset?', description: 'This action is permanent!' }).then(async () => {
      commitMutation({
        variables: { productCode: softwareId, versionId, assetId },
        onCompleted: handleCompleted,
        onError: handleError
      });
    });
  };

  const handleCompleted = (response: DeleteAssetMutation$data, errors: PayloadError[] | null) => {
    responseHandler.handleSuccess(
      errors,
      response.deleteSoftwareAsset.errors,
      [() => enqueueSnackbar('Asset deleted successfully', { variant: 'success' })],
      [() => enqueueSnackbar('An error occured trying to delete this asset', { variant: 'error' })]
    );
  };

  const handleError = (error: Error) => {
    responseHandler.handleError(error, 'An error occured trying to to delete this asset');
  };

  return (
    <>
      <Button color="error" startIcon={<DeleteIcon />} onClick={handleDeleteAsset} disabled={IsMutationInFlight}>
        Delete
      </Button>
    </>
  );
};

export default DeleteAsset;