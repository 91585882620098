import { Autocomplete, TextField } from '@mui/material';
import countryList, { getName, overwrite } from 'country-list';
import React from 'react';
import { IsoValue } from '../dtos/IsoValue';

interface CountrySelectProps {
  value: string | null;
  onCountrySelected: (newValue: IsoValue | null) => void;
  labelOverride?: string;
  disabled?: boolean;
}

export const CountrySelect: React.FC<CountrySelectProps> = ({ value, onCountrySelected, labelOverride, disabled }) => {
  overwrite([{ code: 'GB', name: 'United Kingdom' }]);

  const countries = React.useMemo(() => {
    const uk = ((countryList.getData() as IsoValue[]) ?? []).filter((f) => f.code === 'GB');
    const restOfWorld = ((countryList.getData() as IsoValue[]) ?? []).filter((f) => f.code !== 'GB').sort((a, b) => (a.name > b.name ? 1 : -1));
    return [...uk, ...restOfWorld];
  }, []);

  return (
    <Autocomplete
      fullWidth
      disabled={disabled}
      options={countries}
      autoHighlight
      getOptionLabel={(option) => option.name ?? ''}
      renderInput={(params) => (
        <TextField
          {...params}
          label={labelOverride ?? 'Select Country'}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
      value={{ code: value ?? '', name: getName(value ?? '') ?? '' }}
      onChange={(_, newValue) => onCountrySelected(newValue)}
      // Overriding equality check to prevent console warnings
      isOptionEqualToValue={(opt, val) => opt.code === val.code && opt.name === val.name}
    />
  );
};
