import StandardContainerLayout from '../../layouts/StandardContainerLayout';
import { useAppInsightsPageview } from '../../services/app-insights/useAppInsightsPageview';
import { MyInstruments } from '../dashboard/MyInstruments';
import { SoftwareDownloads } from '../dashboard/SoftwareDownloads';
import { Welcome } from '../welcome/Welcome';

export const DashboardPage: React.FC = () => {
  useAppInsightsPageview('Dashboard');

  return (
    <StandardContainerLayout>
      <Welcome />
      <MyInstruments />
      <SoftwareDownloads />
    </StandardContainerLayout>
  );
};
