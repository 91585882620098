import { Button, CircularProgress, Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { InstrumentTable } from './InstrumentTable';
import { graphql } from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay';
import { MyInstrumentsQuery } from './__generated__/MyInstrumentsQuery.graphql';
import InstrumentSoftwareGroups from './InstrumentSoftwareGroups';

const myInstrumentsQuery = graphql`
  query MyInstrumentsQuery {
    myInstruments @required (action: THROW) {
      edges @required (action: THROW) {
        node @required (action: THROW) {
          id
          customerId @required (action: THROW)
          instrumentId @required (action: THROW)
          modelName @required (action: THROW)
          uniqueCode @required (action: THROW)
          serial @required (action: THROW)
          companyName
          countryCodeAlpha2
          industrySectorName
          installationDate @required (action: THROW)
          warranties {
            id
            instrumentId
            status
            commencementDate
          }
        }
      }
    }
  }
`;

export const MyInstruments: React.FC = () => {

  const instruments = useLazyLoadQuery<MyInstrumentsQuery>(myInstrumentsQuery, {}, { fetchPolicy: 'network-only' });

  return (
    <>
      <Typography variant="h1">My Instruments</Typography>
      {(!instruments) && <CircularProgress />}

      {instruments.myInstruments.edges.length > 0 && (
        <>
          <InstrumentSoftwareGroups instruments={instruments} />

          <Button component={Link} to="/register-instrument" variant="contained" color="primary">
            Register new...
          </Button>

          <Typography variant="h1" mt="2rem">Details</Typography>

          {<InstrumentTable instruments={instruments} />}
        </>
      )}

      {instruments.myInstruments.edges.length === 0 && (
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
          <Paper sx={{ display: 'inline-block', p: '2rem', textAlign: 'center' }}>
            <Typography variant="h2">
              To access relevant information and record proof of ownership for warranty purposes, make sure you register your instrument.
            </Typography>

            <Button component={Link} to="/register-instrument" variant="contained" color="primary" size={'large'}>
              Register new
            </Button>
          </Paper>
        </Grid>
      )}
    </>
  );
};
