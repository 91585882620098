import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAppInsightsPageview } from '../../services/app-insights/useAppInsightsPageview';

interface Row {
  classification: string;
  name: string;
  purpose: string;
  expires: string;
}

const rows: Row[] = [
  {
    classification: 'Essential',
    name: '.AspNetCore.Identity.Application, idsrv.session',
    purpose: 'Enables you to log in to the site securely so we can store your instrument data for you',
    expires: 'Close the web page'
  },
  {
    classification: 'Essential',
    name: '.AspNetCore.Antiforgery.*',
    purpose: 'Helps prevent malicious use of your user account',
    expires: 'Close the web page'
  },
  {
    classification: 'Essential',
    name: 'Novacyt.CookieConsent.FirstPartyAnalytics',
    purpose: 'Records your preferences for opting in or out of cookies',
    expires: 'After 2 years'
  },
  {
    classification: 'Optional (performance)',
    name: 'ai_session',
    purpose: 'Used for Microsoft Application Insights so we can keep track of how many users are accessing the application over time',
    expires: 'After 24 hours'
  },
  {
    classification: 'Optional (performance)',
    name: 'ai_user',
    purpose: 'Used for Microsoft Application Insights so we can monitor the performance and usability of the site',
    expires: 'After 1 year'
  }
];

export const CookiePage: React.FC = () => {
  useAppInsightsPageview('Cookie info');
  const navigate = useNavigate();

  return (
    <>
      <Typography variant="h1">Information about our Cookies.</Typography>

      <TableContainer component={Paper} sx={{ my: 1 }}>
        <Table sx={{}}>
          <TableHead>
            <TableRow>
              <TableCell>Classification</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Purpose</TableCell>
              <TableCell>Expires when</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {row.classification}
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.purpose}</TableCell>
                <TableCell>{row.expires}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
        Back
      </Button>
    </>
  );
};
