import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useAppInsightsPageview } from '../../services/app-insights/useAppInsightsPageview';

export const NotFoundPage: React.FC = () => {
  useAppInsightsPageview('Not found');

  return (
    <>
      <Typography variant="h1">This page doesn&apos;t exist.</Typography>
      <Link to="/">Please return to the homepage</Link>
    </>
  );
};
