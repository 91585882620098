import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { isDate, startOfToday, startOfTomorrow, subYears } from 'date-fns';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { CountrySelect } from '../../helpers/CountrySelect';
import { isInWarranty } from '../dashboard/Warranty';
import { graphql } from 'babel-plugin-relay/macro';
import { useLazyLoadQuery, useMutation } from 'react-relay';
import { RegisterInstrumentMyInstrumentsQuery } from './__generated__/RegisterInstrumentMyInstrumentsQuery.graphql';
import { RegisterInstrumentInstrumentQuery } from './__generated__/RegisterInstrumentInstrumentQuery.graphql';
import { InstrumentToRegisterInput, RegisterInstrumentMutation, RegisterInstrumentMutation$data } from './__generated__/RegisterInstrumentMutation.graphql';
import { PayloadError } from 'relay-runtime';
import { useMutationResponseHandler } from '../../services/app-insights/useMutationResponseHandler';
import { RegisterInstrumentWarrantyCommencementDateQuery } from './__generated__/RegisterInstrumentWarrantyCommencementDateQuery.graphql';

const instrumentsQuery = graphql`
  query RegisterInstrumentMyInstrumentsQuery {
    myInstruments {
      edges {
        node {
          id
          customerId
          instrumentId
          modelName
          uniqueCode
          serial
          companyName
          countryCodeAlpha2
          industrySectorName
          installationDate
        }
      }
    }
  }
`;

const instrumentQuery = graphql`
  query RegisterInstrumentInstrumentQuery ($referenceId: String!) {
    manufacturedInstrumentByReference(referenceId: $referenceId) {
      instrumentId
      serial
      modelName
      uniqueCode
    }
  }
`;

const addInstrument = graphql`
  mutation RegisterInstrumentMutation ($instrumentToRegister: InstrumentToRegisterInput!) {
    addInstrument (input: { instrumentToRegister: $instrumentToRegister }) {
      instrument {
        id
        customerId
        instrumentId
        serial
        modelName
        uniqueCode
        companyName
        countryCodeAlpha2
        industrySectorName
        installationDate
      }
      errors {
        __typename
        ... on Error {
          message
        }
      }
    }
  }
`;

const warrantyCommencementDateQuery = graphql`
  query RegisterInstrumentWarrantyCommencementDateQuery($instrumentId: String!){
    warrantyCommencementDate(instrumentId: $instrumentId)
  }
`;

const sectorList: string[] = [
  'Biotechnology',
  'Pharmaceuticals',
  'Academia',
  'Private Healthcare Clinical Diagnostics',
  'NHS Healthcare Clinical Diagnostics',
  'Veterinary',
  'Food and Beverage Testing',
  'Forensics',
  'Travel and Hospitality/Entertainment',
  'Other'
];

interface RegisterInstrumentProps {
  instrumentNumberOrSerialNumber: string;
  onSuccess: () => void;
  onCancel: () => void;
}

export const RegisterInstrument: React.FC<RegisterInstrumentProps> = ({ instrumentNumberOrSerialNumber, onSuccess, onCancel }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { responseHandler } = useMutationResponseHandler();

  // For checking that this instrument isn't already added.
  const instruments = useLazyLoadQuery<RegisterInstrumentMyInstrumentsQuery>(instrumentsQuery, {});

  const instrumentWarranty = useLazyLoadQuery<RegisterInstrumentWarrantyCommencementDateQuery>(
    warrantyCommencementDateQuery,
    { instrumentId: instrumentNumberOrSerialNumber }
  );

  const manufactured = useLazyLoadQuery<RegisterInstrumentInstrumentQuery>(
    instrumentQuery,
    { referenceId: instrumentNumberOrSerialNumber }
  );

  const [formValues, setFormValues] = useState<InstrumentToRegisterInput>({
    instrumentId: instrumentNumberOrSerialNumber,
    companyName: '',
    countryCodeAlpha2: 'GB',
    industrySectorName: '',
    installationDate: new Date()
  });

  const [success, setSuccess] = useState<boolean>(false);

  const handleAddInstrument = () => {
    commitMutation({
      variables: { instrumentToRegister: { ...formValues } },
      onCompleted: handleCompleted,
      onError: handleError
    });
  };

  const handleCompleted = (response: RegisterInstrumentMutation$data, errors: PayloadError[] | null) => {
    responseHandler.handleSuccess(
      errors,
      response.addInstrument.errors,
      [
        () => {
          enqueueSnackbar(`Congratulations! Your new ${manufactured.manufacturedInstrumentByReference?.modelName} is now registered to you.`, {
            variant: 'success'
          });
        },
        () => { setSuccess(true); },
        () => onSuccess()
      ],
      [() => enqueueSnackbar('Unable to register instrument, please contact customer support', { variant: 'error' })]
    );
  };

  const handleError = (error: Error) => {
    responseHandler.handleError(error, 'Unable to register instrument, please contact customer support');
  };

  const [commitMutation] = useMutation<RegisterInstrumentMutation>(addInstrument);

  // Loading
  if (!instruments.myInstruments || !manufactured.manufacturedInstrumentByReference) return <CircularProgress />;

  // Error
  if (!instruments.myInstruments || !manufactured.manufacturedInstrumentByReference)
    return <Typography>Sorry but there&apos;s been a problem. Please refresh the page and try again.</Typography>;

  if (!instruments || !manufactured.manufacturedInstrumentByReference)
    return (
      <>
        <CircularProgress />
        <Typography pl="1rem">Checking instrument data...</Typography>
      </>
    );

  // Already registered
  if (instruments.myInstruments.edges?.some((f) => f.node.instrumentId === manufactured.manufacturedInstrumentByReference?.instrumentId))
    return (
      <>
        <Typography mb={'1rem'}>This {manufactured.manufacturedInstrumentByReference.modelName} instrument is already registered to you.</Typography>
        <Button variant="contained" color="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </>
    );

  const companyNameError = (): string | null => {
    if (formValues.companyName.length > 0 && formValues.companyName.length < 3) return 'Company name must be at least 3 characters long';
    if (formValues.companyName.length > 128) return 'Company name must no longer than 128 characters';
    return null;
  };

  const dateError = (): string | null => {
    if (!isDate(formValues.installationDate)) return 'Invalid date';
    if (formValues.installationDate >= startOfTomorrow()) return 'Installation date cannot be in the future';
    if (formValues.installationDate <= subYears(startOfToday(), 32)) return 'Installation date cannot be more than 32 years in the past';
    return null;
  };

  const formValid =
    formValues.companyName
    && !companyNameError()
    && formValues.industrySectorName
    && formValues.countryCodeAlpha2
    && formValues.installationDate
    && !dateError();

  const submitForm = async () => {
    handleAddInstrument();
  };

  const sn = manufactured.manufacturedInstrumentByReference.serial?.length ?? 0 > 0 ? `(S/N: ${manufactured.manufacturedInstrumentByReference?.serial})` : '';

  return (
    <>
      <Typography variant="body1" mb="1rem" style={{
        color: isInWarranty(instrumentWarranty.warrantyCommencementDate) ? 'blue' : 'red',
        fontWeight: 'bold', // Add a bold font weight for emphasis (optional)
      }}>
        {isInWarranty(instrumentWarranty.warrantyCommencementDate) ? 'Within warranty period' : 'Out of warranty, please contact customer services'}
      </Typography>
      <Typography variant="body1" mb="1rem">
        Registering new <strong>{manufactured.manufacturedInstrumentByReference.modelName}</strong>
      </Typography>
      <Typography variant="body1" mb="2rem">
        Instrument ID: {manufactured.manufacturedInstrumentByReference.instrumentId}&emsp;&emsp;{sn}
      </Typography>

      <Box width={'25rem'}>
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <TextField
              fullWidth
              label="Company name"
              onChange={(e) => setFormValues((v) => ({ ...v, companyName: e.target.value }))}
              error={companyNameError() !== null}
              helperText={companyNameError()}
            />
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <InputLabel id="industry-label">Industry sector</InputLabel>
              <Select
                labelId="industry-label"
                id="sector-select"
                value={formValues.industrySectorName}
                onChange={(e) => setFormValues((v) => ({ ...v, industrySectorName: e.target.value }))}
                label="Industry sector"
              >
                {sectorList.map((f) => (
                  <MenuItem key={f} value={f}>
                    {f}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <CountrySelect
              labelOverride="Location"
              value={formValues.countryCodeAlpha2}
              onCountrySelected={(country) => setFormValues((v) => ({ ...v, countryCodeAlpha2: country?.code ?? '' }))}
            />
          </Grid>
          <Grid item>
            <DatePicker
              label="Installation date"
              inputFormat="dd/MM/yyyy"
              value={formValues.installationDate}
              onChange={(e) => setFormValues((v) => ({ ...v, installationDate: e ?? new Date() }))}
              renderInput={(params) => <TextField {...params} error={dateError() !== null} helperText={dateError()} />}
            />
          </Grid>

          <Grid item container justifyContent={'space-between'}>
            <Button variant="contained" color="secondary" onClick={onCancel}>
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              disabled={!formValid || success}
              onClick={submitForm}
            >
              Register
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};