import { useAuth } from 'react-oidc-context';

const useAccessToken = (): (() => Promise<string | undefined>) => {
  const auth = useAuth();

  const getAccessToken = async (): Promise<string | undefined> => {
    if (!auth.isAuthenticated) {
      await auth.signinRedirect({ state: window.location.pathname });

      throw Error('Code never gets here');
    }

    return auth.user?.access_token;
  };

  return getAccessToken;
};

export { useAccessToken };
