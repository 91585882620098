import StarBorderIcon from '@mui/icons-material/StarBorder';
import { Card, CardActionArea, CardContent, CardMedia, CircularProgress, Grid, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { Software } from '../../dtos/Software';
import { toDateFormat } from '../../helpers/DateHelper';
import { getImageFromArray } from '../../helpers/ImageSelector';
import { SoftwareDownloadDialog } from './SoftwareDownloadDialog';
import { graphql } from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay';
import { AllSoftwareSoftwareQuery } from './__generated__/AllSoftwareSoftwareQuery.graphql';
import { AllSoftwareInstrumentsQuery } from './__generated__/AllSoftwareInstrumentsQuery.graphql';

const allSoftwareQuery = graphql`
  query AllSoftwareSoftwareQuery {
    allSoftware {
      edges @required (action: THROW) {
        node {
          id
          etag
          name
          currentVersion {
            id
            softwareId
            releaseNumber
            publishedDate
            lastUpdatedDate
            assets {
              id
              softwareVersionId
              name
              description
              type
              size
              location
            }
          }
          supportedInstruments
          versions {
            id
            softwareId
            releaseNumber
            publishedDate
            lastUpdatedDate
          }
        }
      }
    }
  }
`;
const myInstrumentsQuery = graphql`
  query AllSoftwareInstrumentsQuery {
    myInstruments {
      edges {
        node {
          id
          uniqueCode
        }
      }
    }
  }
`;

export const AllSoftware: React.FC = () => {
  const softwares = useLazyLoadQuery<AllSoftwareSoftwareQuery>(allSoftwareQuery, {});

  const instruments = useLazyLoadQuery<AllSoftwareInstrumentsQuery>(myInstrumentsQuery, {});

  const [isOpen, setIsOpen] = React.useState(false);
  const [software, setSoftware] = React.useState<Software | null>(null);

  const getCurrentRelease = (software: Software | null) => {
    if (software) {
      return software?.currentVersion;
    }
    return undefined;
  };

  const handleCardClick = (software: Software) => {
    setSoftware(software);
    setIsOpen(true);
  };

  const recommendedSoftware = (supportedInstruments: string[]) => {
    if (instruments.myInstruments) {
      const myCodes = instruments.myInstruments.edges?.map((f) => f.node.uniqueCode) ?? [];
      if (myCodes.some((i) => supportedInstruments.includes(i))) return true;
    }
    return false;
  };

  return (
    <>
      <Typography variant="h1">Software</Typography>
      <Typography variant="body1" mb="2rem">
        Here you can download any software for any of our instruments, even if you haven't registered one yet.
      </Typography>

      {!softwares.allSoftware && <CircularProgress />}
      {softwares.allSoftware && softwares.allSoftware.edges.length === 0 && <Typography>Software could not be found.</Typography>}

      {softwares.allSoftware && softwares.allSoftware.edges.length > 0 && (
        <Grid container spacing={2}>
          {softwares.allSoftware.edges.map(edge => {
            return {
              id: edge.node.id,
              name: edge.node.name,
              currentVersion: edge.node.currentVersion,
              supportedInstruments: [...edge.node.supportedInstruments],
              versions: []
            } as Software;
          }).map((software, i) => (
            <Grid item xs={12} md={6} key={i}>
              <Card sx={{ p: 0 }}>
                <CardActionArea onClick={() => handleCardClick(software)}>
                  <Grid container>
                    <Grid item>
                      <CardMedia component="img" sx={{ height: '14rem' }} image={getImageFromArray(software.supportedInstruments)} />
                    </Grid>
                    <Grid item flex={1}>
                      <CardContent>
                        <Grid container justifyContent={'space-between'} pb="2rem">
                          <Typography variant="h2" flex={1}>
                            {software.name}
                          </Typography>

                          {recommendedSoftware(software.supportedInstruments) && (
                            <Tooltip title="Recommended Download - A registered instrument uses this software">
                              <StarBorderIcon />
                            </Tooltip>
                          )}
                        </Grid>
                        <Typography variant="body1" pb="1rem">
                          Latest Release: {getCurrentRelease(software)?.releaseNumber ?? 'N/A'}
                        </Typography>
                        <Typography variant="body1">Published: {toDateFormat(getCurrentRelease(software)?.publishedDate)}</Typography>
                      </CardContent>
                    </Grid>
                  </Grid>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      <SoftwareDownloadDialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title={software?.name ?? ''}
        assets={getCurrentRelease(software)?.assets ?? []}
        releaseVersion={getCurrentRelease(software)?.releaseNumber ?? ''}
      />
    </>
  );
};
