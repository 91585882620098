import React from 'react';
import { Container, Typography } from '@mui/material';

/**
 * Displayed in place of actual content when a user is not authorised
 */
const Forbidden: React.FC = () => {
  return (
    <Container sx={{ my: '4rem' }}>
      <Typography variant="h1">Forbidden.</Typography>
      <Typography variant="h5">Please contact your administrator to gain the required permissions.</Typography>
    </Container>
  );
};

export default Forbidden;
