import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { graphql } from 'babel-plugin-relay/macro';
import { Box, Button, Tooltip } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useMutation } from 'react-relay';
import { PayloadError } from 'relay-runtime';
import { useMutationResponseHandler } from '../../services/app-insights/useMutationResponseHandler';
import { DocumentType, DocumentDownloadButtonBlobUrlMutation, DocumentDownloadButtonBlobUrlMutation$data } from './__generated__/DocumentDownloadButtonBlobUrlMutation.graphql';

const blobUrlMutation = graphql`
  mutation DocumentDownloadButtonBlobUrlMutation($instrumentId: String!, $documentFileName: String!, $documentType: DocumentType!) {
    instrumentDocumentUrl(input: { instrumentId: $instrumentId, filename: $documentFileName, documentType: $documentType  }) {
      string
      errors {
        __typename
        ... on Error {
          message
        }
      }
    }
  }
`;

interface DocumentDownloadButtonProps {
  instrumentId: string;
  fileName: string | undefined | null;
  documentType: DocumentType;
  showDownloadButton: boolean;
}

enum DisplayDocumentType {
  COF_C = 'COF_C',
  QC_TEST_CERT = 'QC_TEST_CERT',
  FUTURE_VALUE = '%future added value'
}

const documentTypeDisplayNames: { [key in DocumentType]: string } = {
  [DisplayDocumentType.COF_C]: 'C of C',
  [DisplayDocumentType.QC_TEST_CERT]: 'QC Test Certificate',
  [DisplayDocumentType.FUTURE_VALUE]: 'Future Value'
};

// eslint-disable-next-line func-style
function getDocumentTypeDisplayName(type: DocumentType): string {
  return documentTypeDisplayNames[type] || 'Unknown Document Type';
}

const DocumentDownloadButton: React.FC<DocumentDownloadButtonProps> = ({ instrumentId, fileName, documentType, showDownloadButton }) => {
  const [commitMutation] = useMutation<DocumentDownloadButtonBlobUrlMutation>(blobUrlMutation);
  const { responseHandler } = useMutationResponseHandler();

  const handleError = (error: Error) => {
    responseHandler.handleError(error, 'An error occurred requesting the certificate download URL');
  };

  const handleCompleted = (response: DocumentDownloadButtonBlobUrlMutation$data, errors: PayloadError[] | null) => {
    responseHandler.handleSuccess(
      errors,
      response.instrumentDocumentUrl.errors,
      [
        () => {
          if (response.instrumentDocumentUrl.string) {
            const a = document.createElement('a');
            a.href = response.instrumentDocumentUrl.string;
            a.click();
            a.remove();
          }
        }
      ],
      [() => enqueueSnackbar('An error occurred requesting the certificate download URL', { variant: 'error' })]
    );
  };

  const downloadDocument = async () => {
    if (fileName) {
      commitMutation({
        variables: {
          instrumentId,
          documentFileName: fileName,
          documentType
        },
        onCompleted: handleCompleted,
        onError: handleError
      });
    } else {
      enqueueSnackbar('Filename is missing.', { variant: 'warning' });
    }
  };

  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      {showDownloadButton
        ? <Button
          sx={{
            width: '100%',
            maxWidth: '300px',
            minWidth: '200px'
          }}
          variant="contained"
          color="primary"
          onClick={downloadDocument}
        >
          {getDocumentTypeDisplayName(documentType)}
        </Button>
        : <Tooltip title="Download Certificate">
          <IconButton
            sx={{
              width: '100%',
              maxWidth: '300px',
              minWidth: '200px'
            }}
            aria-label="download"
            onClick={downloadDocument}
          >
            <CloudDownloadIcon />
          </IconButton>
        </Tooltip>
      }
    </Box>
  );
};

export default DocumentDownloadButton;