/**
 * @generated SignedSource<<4c2b66670fd384907dcb8360e4241e19>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type RegisterInstrumentMyInstrumentsQuery$variables = Record<PropertyKey, never>;
export type RegisterInstrumentMyInstrumentsQuery$data = {
  readonly myInstruments: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly companyName: string;
        readonly countryCodeAlpha2: string;
        readonly customerId: string;
        readonly id: string;
        readonly industrySectorName: string;
        readonly installationDate: any;
        readonly instrumentId: string;
        readonly modelName: string;
        readonly serial: string | null | undefined;
        readonly uniqueCode: string;
      };
    }> | null | undefined;
  } | null | undefined;
};
export type RegisterInstrumentMyInstrumentsQuery = {
  response: RegisterInstrumentMyInstrumentsQuery$data;
  variables: RegisterInstrumentMyInstrumentsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MyInstrumentsConnection",
    "kind": "LinkedField",
    "name": "myInstruments",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MyInstrumentsEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Instrument",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "customerId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "instrumentId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "modelName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "uniqueCode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "serial",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "companyName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "countryCodeAlpha2",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "industrySectorName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "installationDate",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RegisterInstrumentMyInstrumentsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "RegisterInstrumentMyInstrumentsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "d35e99fcd5fd31f2ec5edc835ffe5ee1",
    "id": null,
    "metadata": {},
    "name": "RegisterInstrumentMyInstrumentsQuery",
    "operationKind": "query",
    "text": "query RegisterInstrumentMyInstrumentsQuery {\n  myInstruments {\n    edges {\n      node {\n        id\n        customerId\n        instrumentId\n        modelName\n        uniqueCode\n        serial\n        companyName\n        countryCodeAlpha2\n        industrySectorName\n        installationDate\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "aa2b3c17c645af69ccb62e303321a214";

export default node;
