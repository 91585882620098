import { format, isValid, parseISO } from 'date-fns';

export const toDateFormat = (date: Date | undefined, withTime?: boolean): string => {
  if (!date) return 'N/A';
  let dateToFormat = date;
  if (!isValid(date)) {
    dateToFormat = parseISO(date.toString());
    if (!isValid(dateToFormat)) return 'N/A';
  }
  return format(dateToFormat, withTime ? 'HH:mm:ss dd/MM/yy' : 'dd/MM/yyyy');
};
