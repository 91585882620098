import { Typography } from '@mui/material';

export const Welcome: React.FC = () => {
  return (
    <>
      <Typography variant="h1">Welcome</Typography>
      <Typography variant="body1" mb={'0.5rem'}>
        Welcome and thank you for visiting the Novacyt Customer Portal.
      </Typography>
      <Typography variant="body1" mb={'0.5rem'}>
        We want this to be a hub for our valued customers to access our latest software releases for all of our PCR instruments and more.
      </Typography>
      <Typography variant="body1" mb={'0.5rem'}>
        This is just the beginning, though. Over the coming months, we hope to provide you with more content, such as IFUs, test data, user guides,
        and more. Importantly, we welcome your opinions and suggestions, so don't hesitate to contact us with any information you would like to see.
      </Typography>
      <Typography variant="body1" mb={'0.5rem'}>
        Thank you,
      </Typography>
      <Typography variant="body1" mb={'2rem'}>
        The Novacyt team
      </Typography>
    </>
  );
};
