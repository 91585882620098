import { useTheme } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

interface SoftwareReleaseCardProps {
  title?: string;
  children: React.ReactNode;
  actions?: React.ReactNode;
  primary?: boolean;
}

export const SoftwareReleaseCard: React.FC<SoftwareReleaseCardProps> = ({ title, children, actions, primary = false }) => {
  const theme = useTheme();
  const style = primary ? { backgroundColor: theme.palette.primary.main, color: '#ffffff' } : null;

  return (
    <Card sx={style}>
      <CardContent>
        <Typography variant="h2" p={0}>
          {title}
        </Typography>
      </CardContent>
      {children}
      {actions && <CardActions sx={{ display: 'flex', justifyContent: 'right' }}>{actions}</CardActions>}
    </Card>
  );
};
