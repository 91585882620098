import { Grid, Typography } from '@mui/material';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import WaveGrid from './WaveGrid';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { GridContent } from 'itis-helix-react-core';

export interface MarkdownProps {
  /**
   * The title of this Markdown document
   */
  title: string;

  /**
   * The Markdown content. Supports [Github Flavored Markdown](https://github.github.com/gfm/) and [HTML](https://github.com/rehypejs/rehype-raw).
   */
  markdown: string;
}

/**
 * A grid showing a document title and content based on Markdown
 */
const Markdown: React.FC<MarkdownProps> = (props: MarkdownProps) => {
  console.log(props.markdown);
  return (
    <Grid container spacing={0} alignItems="stretch">
      <WaveGrid item xs={12}>
        <GridContent>
          <Typography variant="h1">{props.title}</Typography>
        </GridContent>
      </WaveGrid>
      <GridContent>
        <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>{props.markdown}</ReactMarkdown>
      </GridContent>
    </Grid>
  );
};

export default Markdown;