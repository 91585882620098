import { Button, CssBaseline } from '@mui/material';
import { styled, ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ConfirmProvider } from 'material-ui-confirm';
import { SnackbarProvider, closeSnackbar } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { appTheme } from './themes/theme';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const StyledSnackbarProvider = styled(SnackbarProvider)`
  &.SnackbarItem-variantSuccess {
    background-color: #61a229;
  }
`;

root.render(
  <React.StrictMode>
    <ThemeProvider theme={appTheme}>
      <ConfirmProvider>
        <CssBaseline />
        <StyledSnackbarProvider
          action={(key) => (
            <Button
              onClick={() => closeSnackbar(key)}
              style={{
                height: '100%',
                left: 0,
                position: 'absolute',
                top: 0,
                width: '100%',
                border: 'none'
              }}
            />
          )}
          maxSnack={4}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <App />
          </LocalizationProvider>
        </StyledSnackbarProvider>
      </ConfirmProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
