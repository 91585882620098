import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { AUTH_CLIENT_ID, AUTH_URI, SITE_URI } from './environment-variables';
import { AllSoftwarePage } from './features/site/AllSoftwarePage';
import { CookiePage } from './features/site/CookiePage';
import { DashboardPage } from './features/site/DashboardPage';
import { Homepage } from './features/site/Homepage';
import { LoggingOut } from './features/site/LoggingOut';
import { ManageSoftwarePage } from './features/site/ManageSoftwarePage';
import { NotFoundPage } from './features/site/NotFoundPage';
import { PleaseWait } from './features/site/PleaseWait';
import { RegisterInstrumentPage } from './features/site/RegisterInstrumentPage';
import { SoftwareAssetsPage } from './features/site/SoftwareAssetsPage';
import { SoftwarePage } from './features/site/SoftwarePage';
import { Layout } from './layouts/Layout';
import { createAppInsights } from './services/app-insights/AppInsights';
import { useAppInsightsCookie } from './services/app-insights/useAppInsightsCookie';
import { GuardedElement, HelixAuthProvider } from 'itis-helix-react-core';
import Forbidden from './components/Forbidden';
import { Role } from './services/authentication/Roles';
import NovacytRelayEnvironmentProvider from './providers/NovacytRelayEnvironmentProvider';
import WarrantyApprovalPage from './features/warranty/WarrantyApprovalPage';
import GenesigSoftwareReleaseGuidePage from './features/guides/genesig-software-release/GenesigSoftwareReleaseGuidePage';

const App = () => {
  const aiCookie = useAppInsightsCookie();
  const { appInsights, reactPlugin } = React.useMemo(() => createAppInsights(!aiCookie.isPermitted), [aiCookie.isPermitted]);

  const scope = 'api1 openid roles name';

  return (
    <BrowserRouter>
      <AppInsightsContext.Provider value={reactPlugin}>
        <HelixAuthProvider authClientId={AUTH_CLIENT_ID} authUri={AUTH_URI} scope={scope} siteUri={SITE_URI} silentRenewPath={`${SITE_URI}/silent-renew.html`}>
          <NovacytRelayEnvironmentProvider appInsights={appInsights}>
            <Layout aiCookie={aiCookie}>
              <Routes>
                <Route path="/" element={<Homepage />} />
                <Route path="/dashboard" element={<GuardedElement fallback={<Forbidden />} forceLogin><DashboardPage /></GuardedElement>} />
                <Route path="/register-instrument">
                  <Route path="" element={<RegisterInstrumentPage />} />
                  <Route path=":lookup" element={<RegisterInstrumentPage />} />
                </Route>
                <Route path="/r/:lookup" element={<RegisterInstrumentPage />} />
                <Route path="/all-software" element={<GuardedElement fallback={<Forbidden />} forceLogin><AllSoftwarePage /></GuardedElement>} />
                <Route path="/software-products" element={<GuardedElement permittedRoles={[Role.Admin, Role.GenesigReleaseManager]} fallback={<Forbidden />} forceLogin><SoftwarePage /></GuardedElement>} />
                <Route path="/software/:id" element={<GuardedElement permittedRoles={[Role.Admin, Role.GenesigReleaseManager]} fallback={<Forbidden />} forceLogin><ManageSoftwarePage /></GuardedElement>} />
                <Route path="/software/:id/versions/:versionId/assets" element={<GuardedElement permittedRoles={[Role.Admin, Role.GenesigReleaseManager]} fallback={<Forbidden />} forceLogin><SoftwareAssetsPage /></GuardedElement>} />
                <Route path="/warranty/:id/approve" element={<GuardedElement permittedRoles={[Role.Admin, Role.WarrantyApprovalAdmin]} fallback={<Forbidden />} forceLogin><WarrantyApprovalPage /></GuardedElement>} />
                <Route path="/signin-callback" element={<PleaseWait message="Logging you in..." />} />
                <Route path="/logging-out" element={<LoggingOut />} />
                <Route path="/cookies" element={<CookiePage />} />
                <Route path="*" element={<NotFoundPage />} />
                <Route path="/guides/software-release"
                  element={
                    <GuardedElement permittedRoles={[Role.GenesigReleaseManager, Role.Admin]} fallback={<Forbidden />} forceLogin>
                      <GenesigSoftwareReleaseGuidePage />
                    </GuardedElement>}
                ></Route>
              </Routes>
            </Layout>
          </NovacytRelayEnvironmentProvider>
        </HelixAuthProvider>
      </AppInsightsContext.Provider>
    </BrowserRouter>
  );
};

export default App;
