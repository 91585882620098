import React from 'react';
import WaveImage from '../assets/wave.png';

const Wave: React.FC = () => {
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${WaveImage})`,
          backgroundSize: 'cover',
          height: '100vh',
          width: '100vw',
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: -1
        }}
      />
    </>
  );
};

export default Wave;
