import React, { useState } from 'react';
import { CardMembership } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TableCell, TextField, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import { API_URI } from '../../environment-variables';
import { useAccessToken } from '../../services/authentication/useAccessToken';

export interface WarrantyProofUploadProps {
  showIcon: boolean;
  instrumentId: string;
}

const WarrantyProofUpload: React.FC<WarrantyProofUploadProps> = ({ showIcon, instrumentId }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [contactEmail, setContactEmail] = useState<string | undefined>(undefined);
  const [uploading, setUploading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const getAccessToken = useAccessToken();

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleUpload = () => {
    const fileInput = document.getElementById('file-input') as HTMLInputElement;
    const file = fileInput?.files?.[0];
    if (file) {
      handleFileUpload(file);
      handleDialogClose();
    } else {
      enqueueSnackbar('Please select a file to upload!', { variant: 'error' });
    }
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(new Date(event.target.value));
  };

  const handleContactEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContactEmail(event.target.value);
  };

  const handleFileUpload = async (file: File) => {
    if (file && selectedDate && contactEmail) {
      setUploading(true);

      const token: string | undefined | void = await getAccessToken();

      const formData = new FormData();
      formData.append('file', file);
      formData.append('purchaseDate', selectedDate.toISOString().split('T')[0]);
      formData.append('email', contactEmail);

      const headers = new Headers();
      headers.append('Authorization', `Bearer ${token}`);
      try {
        const response = await fetch(`${API_URI}/File/uploadpop/${instrumentId}`, {
          method: 'POST',
          body: formData,
          headers: headers
        });
        (response.ok)
          ? enqueueSnackbar('The proof of purchase was successfully uploaded', { variant: 'success' })
          : enqueueSnackbar('An error occured while uploading the proof of purchase', { variant: 'error' });

      } catch (error) {
        enqueueSnackbar('An error occured while uploading the proof of purchase', { variant: 'error' });
      }
      finally {
        setUploading(false);
      }
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <TableCell>
        {showIcon ? (
          <Button variant="outlined" color="primary" onClick={handleDialogOpen} disabled={uploading}>
            Upload Proof of Purchase
          </Button>
        ) : (
          <Tooltip title="Upload Proof of Purchase">
            <CardMembership onClick={handleDialogOpen} />
          </Tooltip>
        )}
        <Dialog open={dialogOpen} onClose={handleDialogClose}>
          <DialogTitle>Upload Proof of Purchase</DialogTitle>
          <DialogContent>
            <br />
            <TextField
              id="date"
              label="Date of Purchase"
              type="date"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={selectedDate.toISOString().split('T')[0]}
              style={{ marginBottom: '16px' }}
              onChange={handleDateChange}
            />
            <TextField
              id="emailContact"
              label="Contact Email Address"
              type="email"
              fullWidth
              value={contactEmail}
              style={{ marginBottom: '16px' }}
              onChange={handleContactEmailChange}
            />
            <input id="file-input" type="file" style={{ marginBottom: '16px' }} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">Cancel</Button>
            <Button onClick={handleUpload} color="primary" variant="contained" disabled={uploading}>Upload</Button>
          </DialogActions>
        </Dialog>
      </TableCell>
    </>
  );
};

export default WarrantyProofUpload;