import { graphql } from 'babel-plugin-relay/macro';
import React from 'react';
import { useFragment } from 'react-relay';
import { SoftwareReleaseCard } from './SoftwareReleaseCard';
import EditIcon from '@mui/icons-material/Edit';
import { Button, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import RollbackRelease from './RollbackRelease';
import { SoftwareReleaseContent } from './SoftwareReleaseContent';
import { CurrentReleaseFragment$key } from './__generated__/CurrentReleaseFragment.graphql';

interface CurrentReleaseProps {
  queryRef: CurrentReleaseFragment$key | undefined | null;
}

const fragment = graphql`
  fragment CurrentReleaseFragment on Software {
    currentVersion {
      id
      softwareId
      releaseNumber
      publishedDate
      lastUpdatedDate
      assets {
        id
        name
        description
        type
        size
        location
        softwareVersionId
      }
    }
  }
`;

const CurrentRelease: React.FC<CurrentReleaseProps> = ({ queryRef }) => {
  const data = useFragment(fragment, queryRef);
  const navigate = useNavigate();

  return (
    <>
      {data?.currentVersion &&
        <SoftwareReleaseCard
          title="Current Release"
          primary
          actions={
            data.currentVersion && (
              <>
                <Button
                  variant="contained"
                  onClick={() => navigate(`/software/${data.currentVersion?.softwareId}/versions/${data.currentVersion?.id}/assets`)}
                  startIcon={<EditIcon />}
                  sx={{ mr: '2rem' }}
                >
                  Edit
                </Button>
                <RollbackRelease productCode={data.currentVersion?.softwareId} versionId={data.currentVersion?.id} />
              </>
            )
          }
        >
          {(!data) && <CircularProgress />}
          {data.currentVersion && <SoftwareReleaseContent version={data.currentVersion} primary />}
        </SoftwareReleaseCard>
      }
    </>
  );
};

export default CurrentRelease;