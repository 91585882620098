import { Grid } from '@mui/material';
import React from 'react';
import { InstrumentCard } from './InstrumentCard';
import { InstrumentGroup } from '../../dtos/Instrument';
import { MyInstrumentsQuery$data } from './__generated__/MyInstrumentsQuery.graphql';
import { Asset } from '../../dtos/Software';
import { graphql } from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay';
import { InstrumentSoftwareGroupsQuery } from './__generated__/InstrumentSoftwareGroupsQuery.graphql';

const softwareQuery = graphql`
  query InstrumentSoftwareGroupsQuery {
    allSoftware @required (action: THROW) {
      edges @required (action: THROW) {
        node {
          id
          etag
          name
          currentVersion {
            id
            softwareId
            releaseNumber
            publishedDate
            lastUpdatedDate
            assets {
              id
              softwareVersionId
              name
              description
              type
              size
              location
            }
          }
          supportedInstruments
          versions {
            id
            softwareId
            releaseNumber
            publishedDate
            lastUpdatedDate
          }
        }
      }
    }
  }
`;

export interface InstrumentSoftwareGroupsProps {
  instruments: MyInstrumentsQuery$data;
}

const InstrumentSoftwareGroups: React.FC<InstrumentSoftwareGroupsProps> = ({ instruments }) => {

  const softwares = useLazyLoadQuery<InstrumentSoftwareGroupsQuery>(softwareQuery, {});

  const groups = React.useMemo(() => {
    const groups: InstrumentGroup[] = [];

    instruments.myInstruments.edges.forEach(instrument => {
      const group = groups.find(g => g.modelName === instrument.node.modelName);

      if (group) {
        group.count++;
        if (group.uniqueCodes.includes(instrument.node.uniqueCode)) {
          group.uniqueCodes.push(instrument.node.uniqueCode);
        }
      } else {
        const thisSoftware = softwares.allSoftware.edges.map(i => i.node).find(f => f.supportedInstruments.includes(instrument.node.uniqueCode));
        const thisVersion = thisSoftware?.currentVersion;
        const thisAssets: Asset[] = [];
        thisVersion?.assets.forEach(i => {
          if (i) {
            thisAssets.push({
              id: i.id,
              name: i.name,
              description: i.description,
              type: i.type,
              size: i.size,
              location: i.location ?? ''
            });
          }
        });

        groups.push({
          modelName: instrument.node.modelName,
          softwareAssets: thisAssets,
          uniqueCodes: [instrument.node.uniqueCode],
          count: 1,
          releaseVersion: thisVersion?.releaseNumber || ''
        });
      }
    });

    return groups;
  }, [instruments, softwares]);

  return (
    <Grid container spacing={4} mb={'1rem'}>
      {groups && groups.sort((a, b) => (a.modelName > b.modelName ? 1 : -1)).map((group, index) => (
        <Grid item xs={6} md={4} key={index}>
          <InstrumentCard instrumentGroup={group} />
        </Grid>
      ))}
    </Grid>
  );
};

export default InstrumentSoftwareGroups;