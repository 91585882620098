import { useAuth } from 'react-oidc-context';
import { Role } from './Roles';

const useRoles = (): ((requiresRole?: Role) => boolean) => {
  const auth = useAuth();

  const hasRole = (requiresRole?: Role) => {
    if (!auth.isAuthenticated || auth.isLoading) return false;
    if (!requiresRole) return auth.isAuthenticated; // Caller hasn't requested specific roles, so just return authentication status.

    const roles = auth.user?.profile['role'];

    if (Array.isArray(roles)) {
      return roles.includes(requiresRole);
    } else {
      return roles === requiresRole;
    }
  };

  return hasRole;
};

export { useRoles };
