import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';
import { graphql } from 'babel-plugin-relay/macro';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { useMutation } from 'react-relay';
import { PayloadError } from 'relay-runtime';
import { RollbackReleaseMutation, RollbackReleaseMutation$data } from './__generated__/RollbackReleaseMutation.graphql';
import { useMutationResponseHandler } from '../../services/app-insights/useMutationResponseHandler';

const deleteSoftwareVersion = graphql`
  mutation RollbackReleaseMutation($productCode: ID!, $versionId: ID!) {
    deleteSoftwareVersion(input: { productCode: $productCode, versionId: $versionId }) {
      software {
        id
        etag
        name
        currentVersionId
        currentVersion {
          softwareId
          releaseNumber
          publishedDate
          id
          lastUpdatedDate
          assets {
            softwareVersionId
            name
            description
            type
            size
            id
            location
          }
        }
        versions {
          softwareId
          releaseNumber
          publishedDate
          id
          lastUpdatedDate
        }
      }
      errors {
        __typename
        ... on Error {
          message
        }
      }
    }
  }
`;

export interface RollbackReleaseProps {
  productCode: string;
  versionId?: string;
}

const RollbackRelease: React.FC<RollbackReleaseProps> = ({ productCode, versionId }) => {
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const { responseHandler } = useMutationResponseHandler();

  const [commitMutation, IsMutationInFlight] = useMutation<RollbackReleaseMutation>(deleteSoftwareVersion);

  const handleRemoveVersionAsync = (versionId: string | undefined) => {
    if (!versionId) return;

    confirm({ title: 'Are you sure you want to remove this version?', description: 'This action is permanent!' }).then(async () => {
      commitMutation({
        variables: { productCode, versionId },
        onCompleted: handleCompleted,
        onError: handleError
      });
    });
  };

  const handleCompleted = (response: RollbackReleaseMutation$data, errors: PayloadError[] | null) => {
    responseHandler.handleSuccess(
      errors,
      response.deleteSoftwareVersion.errors,
      [() => enqueueSnackbar('Successfully rolled back the release version.', { variant: 'success' })],
      [() => enqueueSnackbar('An error occured trying to rollback the release', { variant: 'error' })]
    );
  };

  const handleError = (error: Error) => {
    responseHandler.handleError(error, 'An error occured trying to rollback the release');
  };

  return (
    <>
      <LoadingButton
        variant="contained"
        color="error"
        startIcon={<DeleteIcon />}
        loading={IsMutationInFlight}
        onClick={() => handleRemoveVersionAsync(versionId)}
      >
        Remove version
      </LoadingButton>
    </>
  );
};

export default RollbackRelease;