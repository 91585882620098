/**
 * @generated SignedSource<<78f72a911d2996b9c3cbe6f43b8e521f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AssetInput = {
  description?: string | null | undefined;
  id?: string | null | undefined;
  location?: string | null | undefined;
  name?: string | null | undefined;
  size?: string | null | undefined;
  softwareVersion?: SoftwareVersionInput | null | undefined;
  softwareVersionId?: string | null | undefined;
  type?: string | null | undefined;
};
export type SoftwareVersionInput = {
  assets?: ReadonlyArray<AssetInput | null | undefined> | null | undefined;
  lastUpdatedDate?: any | null | undefined;
  publishedDate: any;
  releaseNumber?: string | null | undefined;
  software?: SoftwareInput | null | undefined;
  softwareId?: string | null | undefined;
  versionId?: string | null | undefined;
};
export type SoftwareInput = {
  currentVersionId?: string | null | undefined;
  etag?: string | null | undefined;
  genesigRelease: boolean;
  id?: string | null | undefined;
  name?: string | null | undefined;
  supportedInstruments?: ReadonlyArray<string | null | undefined> | null | undefined;
  versions?: ReadonlyArray<SoftwareVersionInput | null | undefined> | null | undefined;
  versionsJson?: string | null | undefined;
};
export type UploadAssetMutation$variables = {
  asset: AssetInput;
  productCode: string;
  versionId: string;
};
export type UploadAssetMutation$data = {
  readonly uploadSoftwareAsset: {
    readonly asset: {
      readonly description: string;
      readonly id: string;
      readonly location: string | null | undefined;
      readonly name: string;
      readonly size: string;
      readonly softwareVersionId: string;
      readonly type: string;
    } | null | undefined;
    readonly errors: ReadonlyArray<{
      readonly __typename: string;
      readonly message?: string;
    }> | null | undefined;
  };
};
export type UploadAssetMutation = {
  response: UploadAssetMutation$data;
  variables: UploadAssetMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "asset"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "productCode"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "versionId"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "asset",
            "variableName": "asset"
          },
          {
            "kind": "Variable",
            "name": "productCode",
            "variableName": "productCode"
          },
          {
            "kind": "Variable",
            "name": "versionId",
            "variableName": "versionId"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "UploadSoftwareAssetPayload",
    "kind": "LinkedField",
    "name": "uploadSoftwareAsset",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Asset",
        "kind": "LinkedField",
        "name": "asset",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "softwareVersionId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "size",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "location",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "type": "Error",
            "abstractKey": "__isError"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UploadAssetMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UploadAssetMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "fab3c9a9724ebfc685d8c7dacba09ad8",
    "id": null,
    "metadata": {},
    "name": "UploadAssetMutation",
    "operationKind": "mutation",
    "text": "mutation UploadAssetMutation(\n  $productCode: ID!\n  $versionId: ID!\n  $asset: AssetInput!\n) {\n  uploadSoftwareAsset(input: {productCode: $productCode, versionId: $versionId, asset: $asset}) {\n    asset {\n      softwareVersionId\n      name\n      description\n      type\n      size\n      id\n      location\n    }\n    errors {\n      __typename\n      ... on Error {\n        __isError: __typename\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "95c8fa112220d6091e13680ab6c64b15";

export default node;
