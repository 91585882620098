import StandardContainerLayout from '../../layouts/StandardContainerLayout';
import { useAppInsightsPageview } from '../../services/app-insights/useAppInsightsPageview';
import { SoftwareProducts } from '../admin/SoftwareProducts';

export const SoftwarePage: React.FC = () => {
  useAppInsightsPageview('Software products');

  return (
    <StandardContainerLayout>
      <SoftwareProducts />
    </StandardContainerLayout>
  );
};
