/**
 * @generated SignedSource<<213aae9ba935d0df8b25fa5f3000adbf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DocumentType = "COF_C" | "QC_TEST_CERT" | "%future added value";
export type DocumentDownloadButtonBlobUrlMutation$variables = {
  documentFileName: string;
  documentType: DocumentType;
  instrumentId: string;
};
export type DocumentDownloadButtonBlobUrlMutation$data = {
  readonly instrumentDocumentUrl: {
    readonly errors: ReadonlyArray<{
      readonly __typename: string;
      readonly message?: string;
    }> | null | undefined;
    readonly string: string | null | undefined;
  };
};
export type DocumentDownloadButtonBlobUrlMutation = {
  response: DocumentDownloadButtonBlobUrlMutation$data;
  variables: DocumentDownloadButtonBlobUrlMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "documentFileName"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "documentType"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "instrumentId"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "documentType",
            "variableName": "documentType"
          },
          {
            "kind": "Variable",
            "name": "filename",
            "variableName": "documentFileName"
          },
          {
            "kind": "Variable",
            "name": "instrumentId",
            "variableName": "instrumentId"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "InstrumentDocumentUrlPayload",
    "kind": "LinkedField",
    "name": "instrumentDocumentUrl",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "string",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "type": "Error",
            "abstractKey": "__isError"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DocumentDownloadButtonBlobUrlMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "DocumentDownloadButtonBlobUrlMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "c6648b3c855ffd4a651f095004ae861b",
    "id": null,
    "metadata": {},
    "name": "DocumentDownloadButtonBlobUrlMutation",
    "operationKind": "mutation",
    "text": "mutation DocumentDownloadButtonBlobUrlMutation(\n  $instrumentId: String!\n  $documentFileName: String!\n  $documentType: DocumentType!\n) {\n  instrumentDocumentUrl(input: {instrumentId: $instrumentId, filename: $documentFileName, documentType: $documentType}) {\n    string\n    errors {\n      __typename\n      ... on Error {\n        __isError: __typename\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bbac00d3cf9bdf6f48be92265640210c";

export default node;
