import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { APPINSIGHTS_KEY } from '../../environment-variables';

const createAppInsights = (disableCookiesUsage: boolean) => {
  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
  const reactPlugin = new ReactPlugin();
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: APPINSIGHTS_KEY,
      disableCookiesUsage: disableCookiesUsage,
      extensions: [reactPlugin],
      enableAutoRouteTracking: false,
      disableTelemetry: isDev
    }
  });

  appInsights.loadAppInsights();

  return { appInsights, reactPlugin };
};

export { createAppInsights };
