import { Typography } from '@mui/material';
import React, { FC } from 'react';
import { useAuth } from 'react-oidc-context';
import { Role } from '../../services/authentication/Roles';
import { useRoles } from '../../services/authentication/useRoles';
import { PleaseWait } from './PleaseWait';

interface SecureComponentProps {
  forceLogin?: boolean;
  permittedRoles?: Role[];
  children: React.ReactNode;
}

export const SecureComponent: FC<SecureComponentProps> = ({ forceLogin = false, permittedRoles = undefined, children }) => {
  const auth = useAuth();
  const roles = useRoles();
  const [isStartingAuth, setIsStartingAuth] = React.useState(false);

  React.useEffect(() => {
    if (!auth.isAuthenticated && forceLogin) {
      (async () => await auth.signinRedirect({ state: window.location.pathname }))();
      setIsStartingAuth(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.isAuthenticated, forceLogin]);

  React.useEffect(() => {
    if (auth.isAuthenticated) {
      setIsStartingAuth(false);
    }
  }, [auth.isAuthenticated]);

  if (auth.isLoading || isStartingAuth) return <PleaseWait message="Getting your details..." />;
  if (!permittedRoles || permittedRoles.some((f) => roles(f))) return <>{children}</>;
  if (forceLogin) return <Typography>Sorry, you don&apos;t have access to this information.</Typography>;

  return <></>;
};
