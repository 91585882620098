import React from 'react';
import { Container } from '@mui/material';
import Wave from '../components/Wave';

type StandardContainerLayoutProps = {
  waveBackground?: boolean;
  children: React.ReactNode;
}
/**
 * Component wrapping children in a standard container giving padding/margin compatible
 * with original pages that do not use a full-width grid layout following novacyt.com style.
 */
const StandardContainerLayout: React.FC<StandardContainerLayoutProps> = ({ children, waveBackground = false }) => {
  return (
    <Container sx={{ my: '2rem' }}>
      {waveBackground && <Wave />}
      {children}
    </Container>
  );
};

export default StandardContainerLayout;
