/**
 * @generated SignedSource<<e14fcfbdb6d3e1f0c3867eae9829a9e0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SoftwareProductsManageAllSoftwareQuery$variables = Record<PropertyKey, never>;
export type SoftwareProductsManageAllSoftwareQuery$data = {
  readonly manageAllSoftware: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly currentVersion: {
          readonly assets: ReadonlyArray<{
            readonly description: string;
            readonly id: string;
            readonly location: string | null | undefined;
            readonly name: string;
            readonly size: string;
            readonly softwareVersionId: string;
            readonly type: string;
          }>;
          readonly id: string;
          readonly lastUpdatedDate: any | null | undefined;
          readonly publishedDate: any;
          readonly releaseNumber: string;
          readonly softwareId: string;
        } | null | undefined;
        readonly etag: string;
        readonly id: string;
        readonly name: string;
        readonly supportedInstruments: ReadonlyArray<string>;
        readonly versions: ReadonlyArray<{
          readonly id: string;
          readonly lastUpdatedDate: any | null | undefined;
          readonly publishedDate: any;
          readonly releaseNumber: string;
          readonly softwareId: string;
        }>;
      };
    }>;
  } | null | undefined;
};
export type SoftwareProductsManageAllSoftwareQuery = {
  response: SoftwareProductsManageAllSoftwareQuery$data;
  variables: SoftwareProductsManageAllSoftwareQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "softwareId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "releaseNumber",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "publishedDate",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastUpdatedDate",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "ManageAllSoftwareEdge",
  "kind": "LinkedField",
  "name": "edges",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Software",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "etag",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "SoftwareVersion",
          "kind": "LinkedField",
          "name": "currentVersion",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Asset",
              "kind": "LinkedField",
              "name": "assets",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "softwareVersionId",
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "description",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "size",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "location",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "supportedInstruments",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SoftwareVersion",
          "kind": "LinkedField",
          "name": "versions",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SoftwareProductsManageAllSoftwareQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ManageAllSoftwareConnection",
        "kind": "LinkedField",
        "name": "manageAllSoftware",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": (v6/*: any*/),
            "action": "THROW",
            "path": "manageAllSoftware.edges"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SoftwareProductsManageAllSoftwareQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ManageAllSoftwareConnection",
        "kind": "LinkedField",
        "name": "manageAllSoftware",
        "plural": false,
        "selections": [
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c3f49781141ea0e287d691abff34ea47",
    "id": null,
    "metadata": {},
    "name": "SoftwareProductsManageAllSoftwareQuery",
    "operationKind": "query",
    "text": "query SoftwareProductsManageAllSoftwareQuery {\n  manageAllSoftware {\n    edges {\n      node {\n        id\n        etag\n        name\n        currentVersion {\n          id\n          softwareId\n          releaseNumber\n          publishedDate\n          lastUpdatedDate\n          assets {\n            id\n            softwareVersionId\n            name\n            description\n            type\n            size\n            location\n          }\n        }\n        supportedInstruments\n        versions {\n          id\n          softwareId\n          releaseNumber\n          publishedDate\n          lastUpdatedDate\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ea7ac6a0f0e63cd605e18756bad1053e";

export default node;
