const SITE_URI = '__{{novacytSiteUri}}__';
const AUTH_URI = '__{{novacytAuthUri}}__';
const API_URI = '__{{novacytApiUri}}__';
const GRAPHQL_URI = '__{{novacytGraphQLUri}}__';
const AUTH_CLIENT_ID = '__{{novacytAuthClientId}}__';
const APPINSIGHTS_KEY = '__{{novacytAppInsightsKey}}__';
const SUBSCRIPTION_URI = '__{{novacytSubscriptionUri}}__';


export { SITE_URI, AUTH_URI, API_URI, GRAPHQL_URI, AUTH_CLIENT_ID, APPINSIGHTS_KEY, SUBSCRIPTION_URI };
